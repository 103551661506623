import { createSlice } from "@reduxjs/toolkit";

// Load language from localStorage or set default
const initialLanguage = {
  value: localStorage.getItem("languageValue") || "sinhala",
  label: localStorage.getItem("languageLabel") || "සිංහල",
};

const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: initialLanguage,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("languageLabel", action.payload.label);
      localStorage.setItem("languageValue", action.payload.value);
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
