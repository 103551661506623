import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopNav from "../TopNav/TopNav";
import "./DrivingSchool.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BranchCard from "../../Components/BranchCard/BranchCard";
import IdCard from "../../assets/LearnersUIimages/IdCard.png";
import BirthdayCertificate from "../../assets/LearnersUIimages/BirthdayCertificate.png";
import Medical from "../../assets/LearnersUIimages/Medical.png";
import Location from "../../assets/LearnersUIimages/Location.png";
import UserDetailsModal from "../../views/Forms/UserDetailForms/UserDetailsModal";

function DrivingSchool() {
  const location = useLocation();
  const { learner } = location.state;
   const navigate = useNavigate();
  //const [isFormFilled, setIsFormFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);

// const checkFormFilled = () => {
//   if (localStorage.getItem("isFormFilled") === "true") {
//     setIsFormFilled(true);
//     return true;
//   } else {
//     setIsFormFilled(false);
//     return false;
//   }
// };

const handleJoinNowClick = () => {
  // if (checkFormFilled()) {
    navigate("/joinPage");
  // } else {
  //   setShowModal(true);
  // }
};

const handleCloseModal = () => {
  setShowModal(false);
};

  return (
    <div>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>

      {/* Hero Section */}
      <div className="driving-school-hero">
        <div className="text-center">
          <h1>{learner.name}</h1>
          <p>{learner.description}</p>
          <button className="btn" onClick={handleJoinNowClick}>
            Join Now
          </button>
        </div>
      </div>

      {/* Details Section */}
      <div id="details" className="container driving-school-details">
        <div className="row justify-content-center">
          <div className="services-page container">
            {/* Services Section */}
            <section className="services-section text-center my-5">
              <h2>Services</h2>
              <p>What you can get?</p>
              <div className="row pt-4">
                {learner.services &&
                  learner.services.map((service, index) => (
                    <div key={index} className="col-12 col-md-4 mb-4">
                      <div className="card h-100 shadow-sm">
                        <img
                          src={service.image}
                          className="card-img-top"
                          alt={service.name}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{service.name}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>
            {/* End of Services Section */}

            {/* Prices Section */}
            <section className="prices-section text-center my-5">
              <h2>Prices</h2>
              <div className="prices-table">
                <table className="table table-borderless">
                  <tbody>
                    {learner.prices &&
                      learner.prices.map((price, index) => (
                        <tr key={index}>
                          <td className="text-start">{price.type}</td>
                          <td className="text-end">
                            {price.price.toLocaleString()} LKR
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
            {/* End of Prices Section */}
          </div>
        </div>
      </div>

      <div className="p-3" style={{ backgroundColor: "rgba(51, 51, 51, 0.1)" }}>
        <h2 className="text-center mb-4">Required Documents</h2>
        <Container className="mt-5 text-center">
          <Row className="justify-content-center">
            <Col xs={12} sm={6} md={4} className="mb-3">
              <div className="d-flex flex-column align-items-center">
                <div className="py-4 px-1 border border-dark rounded w-100 d-flex justify-content-center">
                  <img src={IdCard} className="img-fluid" alt="ID Card" />
                </div>
                <div className="mt-2">ID Card</div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="mb-3">
              <div className="d-flex flex-column align-items-center">
                <div className="py-4 px-1 border border-dark rounded w-100 d-flex justify-content-center">
                  <img
                    src={BirthdayCertificate}
                    className="img-fluid"
                    alt="Birthday Certificate"
                  />
                </div>
                <div className="mt-2">Birthday Certificate</div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="mb-3">
              <div className="d-flex flex-column align-items-center">
                <div className="py-4 px-1 border border-dark rounded w-100 d-flex justify-content-center">
                  <img src={Medical} className="img-fluid" alt="Medical" />
                </div>
                <div className="mt-2">Medical</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="mt-5">
          <Row className="align-items-center">
            {/* Left Side - Image */}
            <Col xs={12} md={6} className="mb-4 mb-md-0">
              <div className="d-flex justify-content-center">
                <img
                  src={Location}
                  alt="Contact"
                  className="img-fluid rounded"
                  style={{ maxHeight: "400px", objectFit: "cover" }}
                />
              </div>
            </Col>

            {/* Right Side - Contact Info */}
            <Col xs={12} md={6}>
              <div className="p-4">
                <h3 className="mb-4 text-center text-md-start">Contact</h3>
                <p className="mb-3 text-center text-md-start">
                  <strong>Mobile:</strong>{" "}
                  <a href="tel:07105533456">07105533456</a>
                </p>
                <p className="mb-3 text-center text-md-start">
                  <strong>Email:</strong>{" "}
                  <a href="mailto:amallearners@gmail.com">
                    amallearners@gmail.com
                  </a>
                </p>
                <p className="mb-3 text-center text-md-start">
                  <strong>Address: </strong>
                  57/E/B, Kaduwela, Boralesgamuwa
                  {/* , ZIP Code */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="pt-3">
        <div className="p-3" style={{ backgroundColor: "#333" }}>
          <h2 className="text-center mb-4 text-light">Our Branches</h2>
          <Container className="mt-5 text-center">
            {" "}
            {/* Added text-center for the container */}
            <Row className="justify-content-center">
              {learner.branches && learner.branches.length > 0 ? (
                learner.branches.map((branch, index) => (
                  <Col key={index} md={4} className="mb-3">
                    <BranchCard branch={branch} />
                  </Col>
                ))
              ) : (
                <p>No branches available.</p>
              )}
            </Row>
          </Container>
        </div>
      </div>
      {/* Add User Details Modal */}
      <UserDetailsModal showModal={showModal} handleClose={handleCloseModal} />
    </div>
  );
}

export default DrivingSchool;
