export const sinhalaData = {
  description:
    "මෝටර් රථ දාවන බලපත්‍ර සඳහා වන පෙර පුහුණු  ප්‍රශ්න පත්‍ර ඔබට ආකාර දෙකකින් භාවිතකල හැකියාව ඇත. පෙර පුහුණු  ප්‍රශ්න පත්‍ර වලට මුහුණදීම මෙතැනින්  ආරම්භ  කල හැක.",
  paperNo1: "ප්‍රශ්න පත්‍ර අංක 01",
  paperNo2: "ප්‍රශ්න පත්‍ර අංක 02",
  paperNo3: "ප්‍රශ්න පත්‍ර අංක 03",
  paperNo4: "ප්‍රශ්න පත්‍ර අංක 04",
};
export const englishData = {
  description:
    "You can use the pre-practice question papers for driving licenses in two ways. You can start practicing the pre-practice question papers here.",
  paperNo1: "Paper 01",
  paperNo2: "Paper 02",
  paperNo3: "Paper 03",
  paperNo4: "Paper 04",
};
export const tamilData = {
  description:
    "ஓட்டுநர் உரிமங்களுக்கான முன் பயிற்சி வினாத்தாள்களை நீங்கள் இரண்டு வழிகளில் பயன்படுத்தலாம். முன் பயிற்சி வினாத்தாள்களை நீங்கள் இங்கே பயிற்சி செய்யத் தொடங்கலாம்.",
  paperNo1: "வினாத்தாள் எண். 01",
  paperNo2: "வினாத்தாள் எண். 02",
  paperNo3: "வினாத்தாள் எண். 03",
  paperNo4: "வினாத்தாள் எண். 04",
};
