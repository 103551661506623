import React from "react";
import { useLocation, useParams } from "react-router-dom";

function EditLearnersDetails() {
  const { branchName } = useParams();
  const location = useLocation();
  const { formData } = location.state;
  return (
    <div>
      <h2>Branch Name: {branchName}</h2>
      <p>Address: {formData.address}</p>
      <p>Email: {formData.email}</p>
      <p>Contact Numbers: {formData.contactNumbers.join(", ")}</p>
    </div>
  );
}

export default EditLearnersDetails;
