import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SignUp.css";
import { toast } from "react-toastify";

export default function SignUp() {
  const [name, setName] = useState(""); 
  const [phoneNumber, setPhoneNumber] = useState(""); 
  const [regNumber, setRegNumber] = useState(""); 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
  
    try {
      //await signup(email, password);
      const response = await axios.post("https://api-fyfkcojn3q-uc.a.run.app/api/auth/signup", {
        
        name,
        phoneNumber,
        regNumber,
        email,
        password,
      });
      toast.success("User signed up successfully!");
      navigate("/AuthPage");
      return response.data;
    } catch (error) {
      toast.success("Error signing up: " + error.message);
    }
  };

  return (
    <div className="card-signup pb-4 shadow-lg">
      <form onSubmit={handleSignUp}>
        <div className="mb-3 mx-3 position-relative">
          {/* <label htmlFor="name" className="form-label">
            Name
          </label> */}
          <i className="fas fa-user icon-position"></i>
          <input
            type="text"
            className="form-control pl-2"
            id="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="mb-3 mx-3 position-relative">
          {/* <label htmlFor="phoneNumber" className="form-label">
            Mobile Number
          </label> */}
          <i className="fas fa-phone icon-position"></i>
          <input
            type="tel"
            className="form-control pl-2"
            id="phoneNumber"
            placeholder="Mobile number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>

        <div className="mb-3 mx-3 position-relative">
          {/* <label htmlFor="regNumber" className="form-label">
            Registration Number
          </label> */}

          <i className="fas fa-registered icon-position"></i>
          <input
            type="text"
            className="form-control pl-2"
            id="regNumber"
            placeholder="Registration number"
            value={regNumber}
            onChange={(e) => setRegNumber(e.target.value)}
            required
          />
        </div>

        <div className="mb-3 mx-3 position-relative">
          {/* <label htmlFor="email" className="form-label">
            Email
          </label> */}
          <i className="fas fa-envelope icon-position"></i>
          <input
            type="email"
            className="form-control pl-2"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3 mx-3 position-relative">
          {/* <label htmlFor="password" className="form-label">
            Password
          </label> */}
          <i className="fas fa-lock icon-position"></i>
          <input
            type="password"
            className="form-control pl-2"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="d-grid">
          <button
            type="submit"
            className="btn mx-3 btn-register-color btn-block"
            //onClick={handleSignUp}
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
}
