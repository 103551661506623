import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/languageSlice";
import { languageOptions } from "../../dummyData/dummyData";

function LanguageRadioButtons() {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);


  const handleLanguageChanged = (selectedOption) => {

    const selectedPaperValue = localStorage.getItem("paperValue") || "first";

    localStorage.removeItem(`selectedAnswers_${selectedPaperValue}`);
    localStorage.removeItem(`timeLeft_${selectedPaperValue}`);
    localStorage.removeItem(`selectedAnswersOBO_${selectedPaperValue}`);
    localStorage.removeItem(`oBOTimeLeft_${selectedPaperValue}`);
    
    dispatch(setLanguage(selectedOption));
    
  };


  return (
    <Form className="d-flex flex-column align-items-center">
      {languageOptions.map((option) => (
        <Form.Check
          key={option.value}
          type="radio"
          label={option.label}
          name="language"
          value={option.value}
          checked={selectedLanguage?.value === option.value}
          onChange={() => handleLanguageChanged(option)}
          className="my-2"
        />
      ))}
    </Form>
  );
}

export default LanguageRadioButtons;
