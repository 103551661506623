import React, { useState } from "react";
import { useLocation } from "react-router-dom"; 
import "./TopNav.css";
import { Navbar,Nav, Container } from "react-bootstrap";
import Logo from "../../assets/images/lankalearners.png";
import LanguageDropDown from "../../Components/LanguageDropDown/LanguageDropDown";

function TopNav() {
  const location = useLocation(); 
  
   const [expanded, setExpanded] = useState(false);

  const handleNavLinkClick = () => setExpanded(false);

  return (
    <Navbar
      bg="light"
      expand="lg"
      className="justify-content-between pe-3 yellow-pill toplinks topNavNewStyle navbar-expand-md "
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <div className="logo-wrapper">
            <img src={Logo} alt="MechSIT" className="logo-image" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="navbar-toggler"
          onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav" in={expanded}>
          <Nav className="me-auto align-items-center toplinks">
            <Nav.Link
              href="/sri-lanka-driving-license-exam-papers"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/sri-lanka-driving-license-exam-papers"
                  ? "yellow-pill nav-link"
                  : ""
              }
            >
              Papers
            </Nav.Link>
            <Nav.Link
              href="/about-us"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/about-us" ? "yellow-pill nav-link" : ""
              }
            >
              About us
            </Nav.Link>
            <Nav.Link
              href="/contact"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/contact" ? "yellow-pill nav-link" : ""
              }
            >
              Contact
            </Nav.Link>
            {/* <Nav.Link
              href="/userDetailsForm"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/userDetailsForm"
                  ? "yellow-pill nav-link"
                  : ""
              }
            >
              Send Details
            </Nav.Link> */}
            {/* <Nav.Link
              href="/learners"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/learners" ? "yellow-pill nav-link" : ""
              }
            >
              Learners
            </Nav.Link> */}
          </Nav>

          <LanguageDropDown />
        </Navbar.Collapse>
        {/* <Navbar.Brand href="/"> */}
        {/* <h4 className="text-warning p-2 topNavNewStyleHedding">SLDL</h4> */}
        {/* <div className="user-profile-wrapper">
            <img src={Logo} alt="logo" className="user-profile-image" />
          </div>
        </Navbar.Brand> */}
      </Container>
    </Navbar>
  );
}

export default TopNav;
