import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDgmChYIh4nWjZNCb4JY6Tlaq2iqdj0FY8",
  authDomain: "sldl-9e0a0.firebaseapp.com",
  databaseURL: "https://sldl-9e0a0-default-rtdb.firebaseio.com",
  projectId: "sldl-9e0a0",
  storageBucket: "sldl-9e0a0.appspot.com",
  messagingSenderId: "217930465104",
  appId: "1:217930465104:web:f791499498737b89797655",
  measurementId: "G-VXG49DJ81G",
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { googleProvider, facebookProvider };