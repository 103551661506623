import React from 'react'
import { Modal } from "react-bootstrap";
import "./UserWelcomeModal.css";


function UserWelcomeModal({ showModal, handleClose }) {
  return (
    <Modal 
    show={showModal} 
    // onHide={}
    >
      <div className="user-modal-container">
        <div className="user-modal-content">
          <div className="user-welcome-section">
            <h1>Welcome,</h1>
            <h1 className="welcome-modal-student-text">Student!</h1>
          </div>
          <div className="welcome-modal-get-started-section">
            <h2>Let's Get Started</h2>
            <ul>
              <li>✅ Access Learners' Driving Exam Papers</li>
              <li>✅ Find and Join Driving Schools</li>
              <li>✅ Explore Our Mobile App</li>
              <li>✅ Stay Updated with Driving Regulations</li>
              <li>✅ Exclusive Offers and Discounts</li>
            </ul>
            <p>
              Login in to your account.{" "}
              <a href="/" className="welcome-modal-login-link">
                Click here
              </a>
            </p>
            <button className="welcome-modal-continue-btn" onClick={handleClose}>Continue</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UserWelcomeModal
