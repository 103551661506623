import React, { useState, useEffect } from "react";
import TopNav from "../TopNav/TopNav";
import "./Learners.css";
import { useNavigate } from "react-router-dom";
import {
  englishUser,
  sinhalaUser,
  tamilUser,
} from "../../dummyData/UserDetailsFormData";
import DrivingSchoolCard from "../../Components/DrivingSchoolCard/DrivingSchoolCard";
import { useGetAllDrivingSchoolsMutation } from "../../redux/service/drivingSchoolService";

function Learners() {
  const [language, setLanguage] = useState(sinhalaUser);

  const navigate = useNavigate();

  const [drivingSchools, setDrivingSchools] = useState([]);
  const [getAllDrivingSchools] = useGetAllDrivingSchoolsMutation();

  const handleCardClick = (learner) => {
    navigate(`/drivingSchool/${learner.name}`, { state: { learner } });
  };

  useEffect(() => {
    const fetchDrivingSchools = async () => {
      try {
        const response = await getAllDrivingSchools().unwrap();
        setDrivingSchools(response);
      } catch (error) {
        console.error("Failed to fetch driving schools:", error);
      }
    };

    fetchDrivingSchools();

    const selectedLanguageValue =
      localStorage.getItem("languageValue") || "sinhala";
    switch (selectedLanguageValue) {
      case "english":
        setLanguage(englishUser);
        break;
      case "tamil":
        setLanguage(tamilUser);
        break;
      default:
        setLanguage(sinhalaUser);
        break;
    }
  }, [getAllDrivingSchools]);

  return (
    <div>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>
      <div className="container p-1">
        <div className="row justify-content-center align-items-start">
          {/* Sidebar */}
          <div
            style={{ minHeight: "100vh" }}
            className="col-12 col-md-3 justify-content-start align-self-start d-flex flex-column"
          >
            <div className="flex-grow-1"></div>
            <div className="my-5"></div>
            <h3 className="text-start">Driving School</h3>
            <div className="flex-grow-1"></div>
            <div className="flex-grow-1">
              <h6 className="pt-2 text-start">District</h6>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option selected>{language.dropDownTitle}</option>
                {Object.values(language.districtList).map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </select>

              <h6 className="pt-2 text-start">City</h6>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="flex-grow-1">
              <h6 className="text-start">Services</h6>
              <div className="form-check d-flex justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="motorbike_license"
                  id="motorbike_license"
                  value="motorbike_license"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="motorbike_license"
                >
                  Motorbike license.
                </label>
              </div>
              <div className="form-check d-flex justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="three_wheel_license"
                  id="three_wheel_license"
                  value="three_wheel_license"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="three_wheel_license"
                >
                  Three-wheel license.
                </label>
              </div>
              <div className="form-check d-flex justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="light_vehicle_license"
                  id="light_vehicle_license"
                  value="light_vehicle_license"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="light_vehicle_license"
                >
                  Light vehicle license.
                </label>
              </div>
              <div className="form-check d-flex justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="heavy_vehicle_license"
                  id="heavy_vehicle_license"
                  value="heavy_vehicle_license"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="heavy_vehicle_license"
                >
                  Heavy vehicle license.
                </label>
              </div>
            </div>
            <h6>Free Register Your Driving School Here</h6>
            <a href="/authPage">
              <button
                type="button"
                className="btn p-2 px-3 btn-register-custom"
              >
                Register Now
              </button>
            </a>
            <div className="flex-grow-1"></div>
          </div>

          {/* Main Content */}
          <div className="col-12 col-md-9 justify-content-center align-self-start d-flex flex-column ps-3">
            <div className="my-5"></div>
            <div className="flex-grow-1">
              {/* {LearnersData.map((learner, index) => (
                <div key={index} className="p-2 w-100">
                  <div onClick={() => handleCardClick(learner)}>
                    <DrivingSchoolCard
                      name={learner.name}
                      city={learner.city}
                      description={learner.description}
                      image={learner.image}
                    />
                  </div>
                </div>
              ))} */}
              {drivingSchools.length > 0 ? (
                drivingSchools.map((school, index) => (
                  <div key={index} className="p-2 w-100">
                    <div onClick={() => handleCardClick(school)}>
                      <DrivingSchoolCard
                        name={school.name}
                        city={school.city}
                        description={school.description}
                        image={school.image}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>No driving schools available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Learners;
