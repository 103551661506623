import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOBOAnswers, setIsOBOPaper } from "../../redux/paperSlice";
import HomeButton from "../../Components/HomeButton/HomeButton";
import "./SpotPaper.css";
import { englishQuiz } from "../../dummyData/englishQuestionsData";
import { sinhalaQuiz } from "../../dummyData/sinhalaQuestionsData";
import { tamilQuiz } from "../../dummyData/tamilQuestionsData";
import Swal from "sweetalert2";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { PieChart } from "react-minimal-pie-chart";

function SoptPaper() {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);

  const [oBOTimeLeft, setOBDTimeLeft] = useState(null);

  const [selectedAnswersOBO, setselectedAnswersOBO] = useState(
    Array(sinhalaQuiz.totalQuestions).fill(null)
  );

  const [showCorrectAnswer, setShowCorrectAnswer] = useState(
    Array(sinhalaQuiz.totalQuestions).fill(false)
  );

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [results, setResults] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    didntAnswers: 0,
  });

  const [language, setLanguage] = useState(sinhalaQuiz);

  const [questionArray, setQuestionArray] = useState([
    sinhalaQuiz.questionArray1,
  ]);

  useEffect(() => {
    const selectedPaperValue = localStorage.getItem("paperValue") || "first";
    console.log(selectedPaperValue, "selectedPaperValue--------------->");
    let quizData;
    switch (selectedLanguage.value) {
      case "english":
        quizData = englishQuiz;
        break;
      case "tamil":
        quizData = tamilQuiz;
        break;
      default:
        quizData = sinhalaQuiz;
        break;
    }
    const questionKey = `questionArray${
      selectedPaperValue.charAt(0).toUpperCase() + selectedPaperValue.slice(1)
    }`;
    const questions = quizData[questionKey] || [];

    console.log("quizData -----", quizData);
    console.log("Loaded questions:", questions);
    console.log("questionKey --->", questionKey);

    setLanguage(quizData);
    setQuestionArray(questions);

    const oBOsavedAnswers = localStorage.getItem(
      `selectedAnswersOBO_${selectedPaperValue}`
    );
    const oBOsavedTimeLeft = localStorage.getItem(
      `oBOTimeLeft_${selectedPaperValue}`
    );
    if (oBOsavedAnswers) {
      try {
        const parsedAnswers = JSON.parse(oBOsavedAnswers);
        if (parsedAnswers.length === questions.length) {
          setselectedAnswersOBO(parsedAnswers);
        } else {
          setselectedAnswersOBO(Array(language.totalQuestions).fill(null));
          setShowCorrectAnswer(Array(language.totalQuestions).fill(false));
        }
      } catch (e) {
        console.error("Error parsing saved answers:", e);
        setselectedAnswersOBO(Array(language.totalQuestions).fill(null));
        setShowCorrectAnswer(Array(language.totalQuestions).fill(false));
      }
    } else {
      setselectedAnswersOBO(Array(language.totalQuestions).fill(null));
      setShowCorrectAnswer(Array(language.totalQuestions).fill(false));
    }

    if (oBOsavedTimeLeft) {
      try {
        const parsedOBDTimeLeft = JSON.parse(oBOsavedTimeLeft);
        setOBDTimeLeft(parsedOBDTimeLeft);
      } catch (e) {
        console.error("Error parsing saved time left:", e);
        setOBDTimeLeft(3600); // change time
      }
    } else {
      setOBDTimeLeft(null);
    }
    dispatch(
      setOBOAnswers(
        oBOsavedAnswers || Array(language.totalQuestions).fill(null)
      )
    );
    // setselectedAnswersOBO(Array(language.totalQuestions).fill(null));
    // setShowCorrectAnswer(Array(language.totalQuestions).fill(false));
  }, [language.totalQuestions, dispatch, selectedLanguage.value]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && oBOTimeLeft > 0) {
        if (!selectedAnswersOBO.includes(null)) {
          setOBDTimeLeft(3600); 
          setselectedAnswersOBO(Array(language.totalQuestions).fill(null)); // Clear answers
          localStorage.removeItem(
            `selectedAnswersOBO_${
              localStorage.getItem("paperValue") || "first"
            }`
          );
          localStorage.removeItem(
            `oBOTimeLeft_${localStorage.getItem("paperValue") || "first"}`
          );
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [selectedAnswersOBO, oBOTimeLeft, language.totalQuestions]);

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...selectedAnswersOBO];
    newAnswers[index] = answer;
    setselectedAnswersOBO(newAnswers);
    updateScore(newAnswers);
    const newShowCorrectAnswer = [...showCorrectAnswer];
    newShowCorrectAnswer[index] = true;
    setShowCorrectAnswer(newShowCorrectAnswer);
  };

  const updateScore = useCallback(
    (newAnswers) => {
      let score = 0;
      let correctAnswers = 0;
      let wrongAnswers = 0;
      let didntAnswers = 0;
      questionArray.forEach((question, index) => {
        if (newAnswers[index] === question.correct) {
          correctAnswers++;
        } else if (newAnswers[index] === null) {
          if (oBOTimeLeft === 0) {
            didntAnswers++;
          } else {
          }
        } else {
          wrongAnswers++;
        }
      });
      setResults({ score, correctAnswers, wrongAnswers, didntAnswers });
    },
    [questionArray, oBOTimeLeft]
  );

  const formatTime = (time) => {
    // const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `
        
            ${minutes.toString().padStart(2, "0")}
            :${seconds.toString().padStart(2, "0")}`;
  };
  // ${hours.toString().padStart(2, "0")}

  useEffect(() => {
    let timer;
    if (oBOTimeLeft > 0 && selectedAnswersOBO.includes(null)) {
      timer = setTimeout(() => setOBDTimeLeft(oBOTimeLeft - 1), 1000);

      const selectedPaperValue = localStorage.getItem("paperValue") || "first";
      localStorage.setItem(
        `selectedAnswersOBO_${selectedPaperValue}`,
        JSON.stringify(selectedAnswersOBO)
      );
      localStorage.setItem(
        `oBOTimeLeft_${selectedPaperValue}`,
        JSON.stringify(oBOTimeLeft)
      );

      dispatch(setOBOAnswers(selectedAnswersOBO));
    } else if (oBOTimeLeft === 0 || !selectedAnswersOBO.includes(null)) {
      clearTimeout(timer);
      if (oBOTimeLeft === 0) {
        updateScore(selectedAnswersOBO);
        dispatch(setOBOAnswers([]));
        dispatch(setIsOBOPaper(false));

        setShowCorrectAnswer(Array(language.totalQuestions).fill(true));
        clearTimeout(timer);
        const selectedPaperValue =
          localStorage.getItem("paperValue") || "first";
        localStorage.removeItem(`selectedAnswersOBO_${selectedPaperValue}`);
        localStorage.removeItem(`oBOTimeLeft_${selectedPaperValue}`);

        if (!selectedAnswersOBO.includes(null)) {
          setOBDTimeLeft(0);
          dispatch(setOBOAnswers([]));
          setselectedAnswersOBO(Array(language.totalQuestions).fill(null));
          localStorage.removeItem(
            `selectedAnswersOBO_${
              localStorage.getItem("paperValue") || "first"
            }`
          );
          localStorage.removeItem(
            `oBOTimeLeft_${localStorage.getItem("paperValue") || "first"}`
          );
        }
      }
    }
    return () => clearTimeout(timer);
  }, [
    oBOTimeLeft,
    selectedAnswersOBO,
    dispatch,
    language.totalQuestions,
    updateScore,
  ]);

  useEffect(() => {
    let isPopMounted = true;

    const selectedPaperValue = localStorage.getItem("paperValue") || "first";

    const oBOsavedTimeLeft = localStorage.getItem(
      `oBOTimeLeft_${selectedPaperValue}`
    );

    if (
      oBOsavedTimeLeft === null ||
      oBOsavedTimeLeft === undefined ||
      oBOsavedTimeLeft === "0"
    ) {
      dispatch(setOBOAnswers([]));
      setOBDTimeLeft(3600); // change time
      dispatch(setIsOBOPaper(true));
    } else {
      if (isPopMounted) {
        setOBDTimeLeft(JSON.parse(oBOsavedTimeLeft));
        dispatch(setIsOBOPaper(true));
      }
    }

    return () => {
      isPopMounted = false;
      Swal.close();
    };
  }, [language, dispatch]);

  const data = [
    {
      title: language.correctAnswers,
      value: results.correctAnswers,
      color: "#198754",
    },
    {
      title: language.wrongAnswers,
      value: results.wrongAnswers,
      color: "#DC3545",
    },
    {
      title: language.didntAnswers,
      value: results.didntAnswers,
      color: "#FFC107",
    },
  ];

  return (
    <div className="container p-1">
      <div className="row  justify-content-center align-items-start">
        <div className="col-lg-10 col-md-12 col-sm-12 p-1">
          {oBOTimeLeft !== 0 &&
            !drawerOpen &&
            selectedAnswersOBO.includes(null) &&
            window.innerWidth < 992 && (
              <div className="row d-flex">
                <div
                  className={` ${
                    window.innerWidth < 992
                      ? "col-12 pe-1 me-1 tabTopIndicatorStyle"
                      : "col-md-8"
                  }  bg-light fixed-positions-questions show-answer-under m-2`}
                >
                  <div
                    className={`row d-flex justify-content-between ${
                      window.innerWidth < 992 ? "" : "topIndicatorStyle"
                    }`}
                  >
                    <span className="col-md col-lg">
                      {language.answerAllQuestions}
                    </span>
                    <span className="col">
                      {language.timeRemainings}: {formatTime(oBOTimeLeft)} /
                      1:00:00
                    </span>
                    <span className="col-md col-lg">
                      {language.total}: {language.totalQuestions}
                    </span>

                    <div className="">
                      <div className="progress m-2">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{
                            width: `${((3600 - oBOTimeLeft) / 3600) * 100}%`,
                          }}
                          aria-valuenow={((3600 - oBOTimeLeft) / 3600) * 100}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(oBOTimeLeft === 0 || !selectedAnswersOBO.includes(null)) &&
            (window.innerWidth < 1025 ? (
              <div className="border p-4 m-2 row justify-content-center align-items-center topScoreCharts bg-light">
                <div
                  className="CircularProgressbar-path CircularProgressbar-trail CircularProgressbar-text CircularProgressbar-background col-6"
                  style={{ width: 200, height: 200 }}
                >
                  <CircularProgressbarWithChildren
                    value={Math.ceil(
                      (results.correctAnswers / language.totalQuestions) * 100
                    )}
                    maxValue={100}
                  >
                    <div className="Progressbar-text">
                      {results.correctAnswers} / {language.totalQuestions}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>

                <div className="col-md align-self-center">
                  <h4 className=" container py-2 justify-content-center d-flex">
                    {language.score} : {results.correctAnswers} /{" "}
                    {language.totalQuestions}
                  </h4>
                  <h6 className="text-success text-gradient">
                    {language.correctAnswers} : {results.correctAnswers}
                  </h6>
                  <h6 className="text-danger text-gradient">
                    {language.wrongAnswers} : {results.wrongAnswers}
                  </h6>
                  <h6 className="text-warning text-gradient">
                    {language.didntAnswers} : {results.didntAnswers}
                  </h6>
                </div>
              </div>
            ) : (
              <div className="border p-4 m-2 row justify-content-center align-items-center topScoreCharts bg-light">
                <div
                  className="CircularProgressbar-path CircularProgressbar-trail CircularProgressbar-text CircularProgressbar-background col-md"
                  style={{ width: 200, height: 200 }}
                >
                  <CircularProgressbarWithChildren
                    value={Math.ceil(
                      (results.correctAnswers / language.totalQuestions) * 100
                    )}
                    maxValue={100}
                  >
                    <div className="Progressbar-text">
                      {results.correctAnswers} / {language.totalQuestions}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>

                <div className="col-lg" style={{ width: 250, height: 250 }}>
                  <PieChart
                    center={[50, 50]}
                    data={data}
                    lineWidth={40}
                    radius={30}
                  />
                </div>
                <div className="col">
                  <div className="row pieChartLable">
                    {data.map((entry, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: entry.color,
                            marginRight: "5px",
                            borderRadius: "4px",
                          }}
                        ></div>
                        <span>{entry.title}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <h2 className="py-2">
                    {language.score} : {results.correctAnswers} /{" "}
                    {language.totalQuestions}
                  </h2>
                  <h5 className="text-success text-gradient">
                    {language.correctAnswers} : {results.correctAnswers}
                  </h5>
                  <h5 className="text-danger text-gradient">
                    {language.wrongAnswers} : {results.wrongAnswers}
                  </h5>
                  <h5 className="text-warning text-gradient">
                    {language.didntAnswers} : {results.didntAnswers}
                  </h5>
                </div>
              </div>
            ))}
          {oBOTimeLeft !== 0 && selectedAnswersOBO.includes(null) && (
            <div className="pt-3 mt-3"></div>
          )}
          {window.innerWidth < 770 ? (
            oBOTimeLeft !== 0 &&
            selectedAnswersOBO.includes(null) && (
              <div className="pt-5 mt-5"></div>
            )
          ) : (
            <div></div>
          )}

          {window.innerWidth < 770 ? (
            <div className="quiz-container" style={{ overflow: "auto" }}>
              <div className="px-5">
                <div className="pe-2">
                  {questionArray?.map((question, index) => (
                    <div
                      key={question?.questionId}
                      className="p-2 border-bottom border-2 border-dark "
                    >
                      <div className="row justify-content-start text-start question-text-size">
                        {index + 1}. {question?.question}
                      </div>

                      <div className="">
                        {question?.image && (
                          <img
                            src={question.image}
                            alt="Question illustration"
                            className="img-thumbnail imageSize"
                          />
                        )}
                      </div>

                      <div className="row justify-content-evenly">
                        <div className="col-md-12 align-self-start answer-text-size">
                          {question?.answers.map((answer) => (
                            <label
                              key={answer}
                              className={`label-container ${
                                showCorrectAnswer[index] &&
                                answer === question?.correct
                                  ? selectedAnswersOBO[index] ===
                                    question?.correct
                                    ? "alert alert-success spotPaperAnswersAlertMargine"
                                    : selectedAnswersOBO[index] === null &&
                                      oBOTimeLeft === 0
                                    ? "alert alert-warning spotPaperAnswersAlertMargine"
                                    : "alert alert-danger spotPaperAnswersAlertMargine"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name={`question-${index}`}
                                disabled={
                                  showCorrectAnswer[index] || oBOTimeLeft === 0
                                }
                                checked={selectedAnswersOBO[index] === answer}
                                onChange={() =>
                                  handleAnswerChange(index, answer)
                                }
                                className="answersRadioButton"
                              />
                              {question?.answers.indexOf(answer) + 1}. {answer}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="quiz-container mt-5" style={{ overflow: "auto" }}>
              <div className="px-5">
                <div className="pe-3 ">
                  {questionArray?.map((question, index) => (
                    <div
                      key={question?.questionId}
                      className="quection-area p-2 border-bottom border-2 border-dark "
                    >
                      <div className="question-text">
                        <div className="row justify-content-start text-start question-text-size">
                          {index + 1}. {question?.question}
                        </div>
                        <div className="col-md-12 align-self-end ">
                          {question?.image && (
                            <div className="image-container">
                              <img
                                src={question.image}
                                alt="Question illustration"
                                className="img-thumbnail float-end imageSize image-style"
                              />
                            </div>
                          )}
                        </div>
                        <div className="row justify-content-start">
                          <div className="col-md-10 align-self-start answer-text-size">
                            {question?.answers.map((answer) => (
                              <label
                                key={answer}
                                className={`label-container ${
                                  showCorrectAnswer[index] &&
                                  answer === question?.correct
                                    ? selectedAnswersOBO[index] ===
                                      question?.correct
                                      ? "alert alert-success spotPaperAnswersAlertMargine"
                                      : selectedAnswersOBO[index] === null &&
                                        oBOTimeLeft === 0
                                      ? "alert alert-warning spotPaperAnswersAlertMargine"
                                      : "alert alert-danger spotPaperAnswersAlertMargine"
                                    : ""
                                }`}
                              >
                                <input
                                  type="radio"
                                  name={`question-${index}`}
                                  disabled={
                                    showCorrectAnswer[index] ||
                                    oBOTimeLeft === 0
                                  }
                                  checked={selectedAnswersOBO[index] === answer}
                                  onChange={() =>
                                    handleAnswerChange(index, answer)
                                  }
                                  className="answersRadioButton"
                                />
                                {question?.answers.indexOf(answer) + 1}.{" "}
                                {answer}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* {(showCorrectAnswer[index] || oBOTimeLeft === 0) && (
                                    <div
                                    className={`show-answer-container ${
                                        showCorrectAnswer[index]
                                        ? selectedAnswersOBO[index] === question.correct
                                         ? "alert alert-success"
                                            : selectedAnswersOBO[index] === null && oBOTimeLeft === 0
                                            ? "alert alert-warning"
                                            : "alert alert-danger"
                                        : ""
                                    }`}
                                    role="alert"
                                    >
                                    {`${
                                        showCorrectAnswer[index]
                                        ? selectedAnswersOBO[index] === question.correct
                                            ? "correct-answer"
                                            : selectedAnswersOBO[index] === null && oBOTimeLeft === 0
                                            ? "Didn't Answer"
                                            : "Wrong Answer"
                                        : ""
                                    }`}
                                    {(showCorrectAnswer[index] || oBOTimeLeft === 0) && (
                                        <div className="answer-reveal">
                                        Correct Answer: {question.correct}
                                        </div>
                                    )}
                                    </div>
                                )} */}
                    </div>
                  ))}
                  {/* {!showCorrectAnswer.includes(true) && (
                        <button className="answer-button" onClick={handleSubmit}>
                        Submit
                        </button>
                    )} */}
                </div>
              </div>
            </div>
          )}
        </div>

        {!(window.innerWidth < 768) && (
          <div className="col-md-2 align-self-star bd-sidebar">
            <div className="row bg-light p-3 border position-fixed h-100 ">
              <div className="col align-self-center">
                {/*-------------------------*/}
                <HomeButton home={language.homeButton} />
                {/*-------------------------*/}
                <div className="timer">
                  {language.timeRemainings}: {formatTime(oBOTimeLeft)}
                </div>
                <h5>
                  {language.score} : {results.correctAnswers} /{" "}
                  {language.totalQuestions}
                </h5>
              </div>
              <div>
                <div className="row row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-2 g-lg-3 m-1">
                  {Array.from({ length: language.totalQuestions }).map(
                    (_, i) => (
                      <div key={i}>
                        <button
                          className={`btn p-1 ${
                            showCorrectAnswer[i]
                              ? selectedAnswersOBO[i] ===
                                questionArray[i].correct
                                ? "btn-success"
                                : selectedAnswersOBO[i] === null &&
                                  oBOTimeLeft === 0
                                ? "btn-warning"
                                : "btn-danger"
                              : "btn-warning"
                          }`}
                          onClick={() =>
                            window.scrollTo(
                              0,
                              document.body.scrollHeight *
                                (i / language.totalQuestions)
                            )
                          }
                        >
                          {i < 9 ? "0" + (i + 1) : i + 1}
                        </button>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {window.innerWidth < 992 && (
          <button
            className="btn btn-primary fixed-bottom m-3"
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              width: "auto",
              zIndex: "100",
            }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {drawerOpen ? "Close Menu" : "Open Menu"}
          </button>
        )}

        {drawerOpen && window.innerWidth < 992 && (
          <div className="drawer bg-light position-fixed p-3">
            {oBOTimeLeft !== 0 && selectedAnswersOBO.includes(null) && (
              <div className="pt-2 mt-2"></div>
            )}
            <div className="timer">
              {language.timeRemainings} : {formatTime(oBOTimeLeft)}
            </div>
            <h2>{language.score} :</h2>
            <h2>
              {results.correctAnswers} / {language.totalQuestions}
            </h2>

            <div className="row row-cols-5 row-cols-lg-5 g-2 g-lg-3">
              {Array.from({ length: language.totalQuestions }).map((_, i) => (
                <div key={i}>
                  <button
                    className={`btn p-1 ${
                      showCorrectAnswer[i]
                        ? selectedAnswersOBO[i] === questionArray[i].correct
                          ? "btn-success"
                          : selectedAnswersOBO[i] === null && oBOTimeLeft === 0
                          ? "btn-warning"
                          : "btn-danger"
                        : "btn-warning"
                    }`}
                    onClick={() =>
                      window.scrollTo(
                        0,
                        document.body.scrollHeight *
                          (i / language.totalQuestions)
                      )
                    }
                  >
                    {i < 9 ? "0" + (i + 1) : i + 1}
                  </button>
                </div>
              ))}
            </div>
            {/*-------------------------*/}
            <div className="p-2">
              <HomeButton home={language.homeButton} />
            </div>
            {/*-------------------------*/}
          </div>
        )}
      </div>
    </div>
  );
}

export default SoptPaper;
