import React, { useState } from "react";
import Swal from "sweetalert2";
import { Container } from "react-bootstrap";
import { useAddDrivingSchoolMutation } from "../../../../redux/service/drivingSchoolService";
import OwnerWelcomeModal from "../OwnerModals/OwnerWelcomeModal/OwnerWelcomeModal"

function OwnerModalForm({ handleClose, language}) {
  const [addNewOwner] = useAddDrivingSchoolMutation();
  const [showOwnerWelcomeModal, setShowOwnerWelcomeModal] = useState(false);
  const [ownerData, setOwnerData] = useState({
    name: "",
    description: "",
    email: "",
    phoneNumber: "",
    address: "",
    logo: "",
    city: "",
    district: "",
    nic: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value, name, type } = e.target;
    if (type === "radio") {
      setOwnerData({ ...ownerData, [name]: value });
    } else {
      setOwnerData({ ...ownerData, [id]: value });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!ownerData.name) {
      formErrors.name = "First name is required";
      isValid = false;
    }
    if (!ownerData.description) {
      formErrors.description = "Last name is required";
      isValid = false;
    }
    if (!ownerData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(ownerData.email)) {
      formErrors.email = "Email address is invalid";
      isValid = false;
    }
    if (!ownerData.phoneNumber) {
      formErrors.phoneNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(ownerData.phoneNumber)) {
      formErrors.phoneNumber = "Mobile number must be 10 digits";
      isValid = false;
    }
    // if (!ownerData.nic) {
    //   formErrors.nic = "NIC number is required";
    //   isValid = false;
    // } else if (!/^\d{9}[VXvx]$|^\d{12}$/.test(ownerData.nic)) {
    //   formErrors.nic = "NIC must be 9 digits followed by V/X or 12 digits.";
    //   isValid = false;
    // }
    if (!ownerData.city) {
      formErrors.city = "City is required";
      isValid = false;
    }
    // if (!ownerData.district) {
    //   formErrors.district = "District is required";
    //   isValid = false;
    // }
    if (!ownerData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    }
    if (!ownerData.logo) {
      formErrors.logo = "Logo is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    //setShowOwnerWelcomeModal(true);
    e.preventDefault();
    if (validateForm()) {
      try {
        const result = await addNewOwner(ownerData).unwrap();

        // const response = await axios.post(
        //   `https://api-fyfkcojn3q-uc.a.run.app/api/user/addUser`,
        //   formData,
        // );
        console.log("Owner Data:", ownerData);
        // Swal.fire({
        //   title: "Success",
        //   text: "Data submitted successfully!",
        //   icon: "success",
        //   confirmButtonText: "OK",
        // });
        handleClose();
        setShowOwnerWelcomeModal(true);
        //navigate("/joinPage");
        // return response.data;
        return result;
      } catch (error) {
        console.error("Error adding document: ", error);
        Swal.fire({
          title: "Error",
          text: "Failed to submit data. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        throw error;
      }
    }
  };

  return (
    <div>
      <Container fluid className="d-flex justify-content-center pb-5">
        <form className="row g-3 p-4">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              {language?.name}
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={ownerData.name}
              onChange={handleInputChange}
            />
            {errors.name && <p className="text-danger">{errors.name}</p>}
          </div>
          <div className="col-md-6">
            <label htmlFor="description" className="form-label">
              {language?.description}
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              value={ownerData.description}
              onChange={handleInputChange}
            />
            {errors.description && (
              <p className="text-danger">{errors.description}</p>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              {language?.email}
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={ownerData.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          {/* <div className="col-md-6">
            <label htmlFor="nic" className="form-label">
              {language.nic}
            </label>
            <input
              id="nic"
              value={ownerData.nic}
              onChange={handleInputChange}
              className="form-control"
              type="text"
            />
            {errors.nic && <p className="text-danger">{errors.nic}</p>}
          </div> */}
          <div className="col-md-4">
            <label htmlFor="phoneNumber" className="form-label">
              {language?.mobileNumber}
            </label>
            <input
              id="phoneNumber"
              value={ownerData.phoneNumber}
              onChange={handleInputChange}
              className="form-control"
              type="text"
            />
            {errors.phoneNumber && (
              <p className="text-danger">{errors.phoneNumber}</p>
            )}
          </div>
          {/* <div className="col-md-4">
            <label htmlFor="dob" className="form-label">
              {language.dob}
            </label>
            <input
              id="dob"
              className="form-control"
              type="date"
              value={ownerData.dob}
              onChange={handleInputChange}
            />
            {errors.dob && <p className="text-danger">{errors.dob}</p>}
          </div> */}
          <div className="col-md-6">
            <label htmlFor="logo" className="form-label">
              {language?.logo}
            </label>
            <input
              type="text"
              className="form-control"
              id="logo"
              value={ownerData.logo}
              onChange={handleInputChange}
            />
            {errors.logo && <p className="text-danger">{errors.logo}</p>}
          </div>
          <div className="col-md-6">
            <label htmlFor="city" className="form-label">
              {language?.city}
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={ownerData.city}
              onChange={handleInputChange}
            />
            {errors.city && <p className="text-danger">{errors.city}</p>}
          </div>
          {/* <div className="col-md-6">
            <label htmlFor="district" className="form-label">
              {language.district}
            </label>
            <select
              className="form-select"
              id="district"
              value={ownerData.district}
              onChange={handleInputChange}
            >
              <option value="">{language.dropDownTitle}</option>
              {Object.keys(language.districtList).map((key) => (
                <option key={key} value={language.districtList[key]}>
                  {language.districtList[key]}
                </option>
              ))}
            </select>
            {errors.district && (
              <p className="text-danger">{errors.district}</p>
            )}
          </div> */}
          <div className="col-12">
            <label htmlFor="address" className="form-label">
              {language?.address}
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={ownerData.address}
              onChange={handleInputChange}
            />
            {errors.address && <p className="text-danger">{errors.address}</p>}
          </div>

          <div className="col-12">
            <button
              type="submit"
              className="btn submit-btn text-light px-4 py-2 mx-2"
              onClick={handleSubmit}
            >
              {language?.submit}
            </button>
          </div>
        </form>
      </Container>
      <div>
        <OwnerWelcomeModal
          showModal={showOwnerWelcomeModal}
          //handleClose={handleClose}
        />
      </div>
    </div>
  );
}

export default OwnerModalForm;
