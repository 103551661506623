import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EditProfile.css";
import {
  FaUpload,
  FaEdit,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import {
  useUpdateDrivingSchoolMutation,
  useGetSingleDrivingSchoolMutation,
} from "../../../redux/service/drivingSchoolService";
import mainImg from "../../../../src/assets/LearnersBranches/mainImg.png";

function EditProfile({ drivingSchoolId }) {
  const navigate = useNavigate();
  const [getSingleDrivingSchool] = useGetSingleDrivingSchoolMutation();
  const [updateDrivingSchool, { isLoading, isError, isSuccess }] =
    useUpdateDrivingSchoolMutation();

  const [formData, setFormData] = useState({
    name: "",
    regNumber: "",
    city: "",
    description: "",
    phoneNumber: "",
    email: "",
    address: "",
    logo: "",
    services: [],
    prices: [],
    branches: [],
  });


  drivingSchoolId = localStorage.getItem("userId");

  useEffect(() => {
    async function fetchDrivingSchool() {
      try {
        const response = await getSingleDrivingSchool({
          drivingSchoolId,
        }).unwrap();
        setFormData(response);
      } catch (error) {
        console.error("Error fetching driving school:", error);
      }
    }
    fetchDrivingSchool();
  }, [drivingSchoolId, getSingleDrivingSchool]);

  const [branches, setBranches] = useState([formData.branches]);

  // const handleCardClick = (branch) => {
  //   const updatedBranches = [...branches];
  //   updatedBranches[0] = branch; 
  //   setBranches(updatedBranches);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateDrivingSchool({ drivingSchoolId, data: formData }).unwrap();
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  const handleOwnerEditProfile = (formData) => {
    navigate(`/admin/editProfile/editOwnerDetails/${formData.id}`, {
      state: { formData },
    });
  };

const handleDrvgShlEditProfile = (branch) => {
  
    navigate(`/admin/editProfile/editLearnersDetails/${branch.branchName}`, {
      state: { formData: branch },
    });
  
};
  useEffect(() => {
    console.log("formData.branches ===>", formData?.branches);
    if (formData?.branches) {
      setBranches(formData.branches);
    }
  }, [formData]);

  console.log(branches, "Branches ===> ");

  return (
    <div className="edit-profile-container">
      <h2 className="edit-profile-title">Edit Profile</h2>

      <div className="profile-section-container">
        <div className="upload-photo-container">
          <FaUpload className="upload-icon" />
          <label>Click to upload</label>
        </div>

        <div className="profile-details-container">
          <div className="edit-photo-container">
            <FaEdit
              onClick={() => handleOwnerEditProfile(formData)}
              className="edit-icon"
            />
          </div>
          <div>
            <label className="input-label">Learners Name</label>
            <input
              type="text"
              className="input-field"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Reg No</label>
            <input
              type="text"
              className="input-field"
              name="regNumber"
              value={formData.regNumber}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ gridColumn: "span 2" }}>
            <label className="input-label">Description</label>
            <textarea
              type="text"
              className="text-area"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Email</label>
            <input
              type="email"
              className="input-field"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Mobile Numbers</label>
            <input
              type="text"
              className="input-field"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ gridColumn: "span 2" }}>
            <label className="input-label">Address</label>
            <input
              type="text"
              className="input-field"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="container my-4">
        {/* Main Branch */}
        <div className="edit-branch-card mb-4 p-3 shadow-sm">
          <div className="row g-0 align-items-center">
            <div className="col-md-3">
              <img
                src={mainImg}
                alt="Main Branch"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-9">
              <div className="edit-branch-card-body">
                <span className="edit-branch-badge bg-secondary mb-2">
                  {formData.regNumber}
                </span>
                <h3 className="edit-branch-card-title mb-3">
                  {formData.name} Colombo Branch
                  {/*Nimal Learners {branches[0].branchName}*/}
                </h3>
                <p className="edit-branch-card-text mb-1">
                  <FaEnvelope className="me-2" /> {formData.email}
                </p>
                <p className="edit-branch-card-text mb-1">
                  <FaMapMarkerAlt className="me-2" /> {formData.address}
                </p>
                <p className="edit-branch-card-text">
                  <FaPhoneAlt className="me-2" /> 011 2509133 | 071 3145202
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Other Branches */}
        <div className="row">
          {formData.branches.length > 0 ? (
            formData.branches.map((branch, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div
                  onClick={() => handleDrvgShlEditProfile(branch)}
                  className="card h-100 shadow-sm"
                >
                  <img
                    src={mainImg}
                    alt={branch.branchName}
                    className="edit-branch-card-img-top"
                  />
                  <div className="edit-branch-card-body">
                    <h5 className="edit-branch-card-title">
                      {branch.branchName}
                    </h5>
                    <p className="edit-branch-card-text mb-1">
                      <FaEnvelope className="me-2" /> {branch.email}
                    </p>
                    <p className="edit-branch-card-text mb-1">
                      <FaMapMarkerAlt className="me-2" /> {branch.address}
                    </p>
                    <p className="edit-branch-card-text">
                      <FaPhoneAlt className="me-2" />{" "}
                      {branch.contactNumbers.join(" | ")}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Branches available.</p>
          )}
        </div>
      </div>

      <div className="services-documents-container">
        <div className="selected-services-container">
          <h3>Selected Services</h3>
          {[
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
          ].map((service, index) => (
            <div key={index} className="service-item-container">
              <input type="checkbox" />
              <label className="checkbox-label">{service}</label>
              <input
                type="text"
                className="service-price-input"
                placeholder="Enter Price"
              />
            </div>
          ))}
        </div>

        <div className="required-documents-container">
          <h3>Required Documents</h3>
          {[
            "Birthday Certificate",
            "National Identity Card",
            "Medical Report",
            "Report (Only Heavy Vehicles)",
          ].map((doc, index) => (
            <div key={index} className="document-item-container">
              <input type="checkbox" />
              <label className="checkbox-label">{doc}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="branches-section-container">
        <h3>Branches</h3>
        <div>
          <label className="input-label">District</label>
          <select className="dropdown">
            <option>Select...</option>
          </select>
        </div>
        <div>
          <label className="input-label">City</label>
          <select className="dropdown">
            <option>Select...</option>
          </select>
        </div>
        <div>
          <label className="input-label">Reg No</label>
          <input type="text" className="input-field" />
        </div>
        <div>
          <label className="input-label">Address</label>
          <input type="text" className="input-field" />
        </div>
        <div>
          <label className="input-label">Email</label>
          <input type="email" className="input-field" />
        </div>
        <div>
          <label className="input-label">Mobile Number</label>
          <input type="text" className="input-field" />
        </div>
        <div className="has-offers-container">
          <label className="input-label">Has Offers</label>
          <input type="checkbox" />
        </div>
      </div>

      <div className="save-button-container">
        <button
          className="save-button"
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
      {isError && (
        <p className="error-message">
          Error updating profile. Please try again.
        </p>
      )}
      {isSuccess && (
        <p className="success-message">Profile updated successfully!</p>
      )}
    </div>
  );
}

export default EditProfile;
