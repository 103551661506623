import React from 'react'
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaCheckCircle,
} from "react-icons/fa";

function BranchCard({ branch }) {
  return (
    <div
      className="branch-card p-3 text-light"
      style={{ backgroundColor: "#333" }}
    >
      <h5 className="text-warning">{branch.branchName}</h5>
      <p>
        <FaMapMarkerAlt className="me-2" />
        {branch.branchAddress}
      </p>
      <p>
        <FaPhoneAlt className="me-2" />
        {branch.branchContactNum[0]}
        <br />
        {branch.branchContactNum[1]}
      </p>
      <p>
        <FaEnvelope className="me-2" />
        {branch.email}
      </p>
      <p>
        <FaCheckCircle className="me-2" />
        {branch.regNo}
      </p>
    </div>
  );
}

export default BranchCard
