import img1 from "../assets/quectionsImages/1.jpg";
import img2 from "../assets/quectionsImages/2.jpg";
import img3 from "../assets/quectionsImages/3.jpg";
import img4 from "../assets/quectionsImages/4.jpg";
import img5 from "../assets/quectionsImages/5.jpg";
import img6 from "../assets/quectionsImages/6.jpg";
import img7 from "../assets/quectionsImages/7.jpg";
import img8 from "../assets/quectionsImages/8.jpg";
import img9 from "../assets/quectionsImages/9.jpg";
import img10 from "../assets/quectionsImages/10.jpg";
import img11 from "../assets/quectionsImages/11.jpg";
import img12 from "../assets/quectionsImages/12.jpg";
import img13 from "../assets/quectionsImages/13.jpg";
import img14 from "../assets/quectionsImages/14.jpg";
import img15 from "../assets/quectionsImages/15.jpg";
import img16 from "../assets/quectionsImages/16.jpg";
import img17 from "../assets/quectionsImages/17.jpg";
import img18 from "../assets/quectionsImages/18.jpg";
import img19 from "../assets/quectionsImages/19.jpg";
import img20 from "../assets/quectionsImages/20.jpg";
import img21 from "../assets/quectionsImages/21.jpg";
import img22 from "../assets/quectionsImages/22.jpg";
import img23 from "../assets/quectionsImages/23.jpg";
import img24 from "../assets/quectionsImages/24.jpg";
import img25 from "../assets/quectionsImages/25.jpg";
import img26 from "../assets/quectionsImages/26.jpg";
import img27 from "../assets/quectionsImages/27.jpg";
import img28 from "../assets/quectionsImages/28.jpg";
import img29 from "../assets/quectionsImages/29.jpg";
import img30 from "../assets/quectionsImages/30.jpg";
import img31 from "../assets/quectionsImages/31.jpg";
import img32 from "../assets/quectionsImages/32.jpg";
import img33 from "../assets/quectionsImages/33.jpg";
import img34 from "../assets/quectionsImages/34.jpg";
import img35 from "../assets/quectionsImages/35.jpg";
import img36 from "../assets/quectionsImages/36.jpg";
import img37 from "../assets/quectionsImages/37.jpg";
import img38 from "../assets/quectionsImages/38.jpg";
import img39 from "../assets/quectionsImages/39.jpg";
import img40 from "../assets/quectionsImages/40.jpg";
import img44 from "../assets/quectionsImages/44.jpg";
import img45 from "../assets/quectionsImages/45.jpg";
import img46 from "../assets/quectionsImages/46.jpg";
import img47 from "../assets/quectionsImages/47.jpg";
import img48 from "../assets/quectionsImages/48.jpg";
import img49 from "../assets/quectionsImages/49.jpg";
import img50 from "../assets/quectionsImages/50.jpg";
import img51 from "../assets/quectionsImages/51.jpg";
import img52 from "../assets/quectionsImages/52.jpg";
import img53 from "../assets/quectionsImages/53.jpg";
import img54 from "../assets/quectionsImages/54.jpg";
import img55 from "../assets/quectionsImages/55.jpg";
import img56 from "../assets/quectionsImages/56.jpg";
import img57 from "../assets/quectionsImages/57.jpg";
import img58 from "../assets/quectionsImages/58.jpg";
import img59 from "../assets/quectionsImages/59.jpg";
import img60 from "../assets/quectionsImages/60.jpg";
import img61 from "../assets/quectionsImages/61.jpg";
import img62 from "../assets/quectionsImages/62.jpg";
import img63 from "../assets/quectionsImages/63.jpg";
import img64 from "../assets/quectionsImages/64.jpg";
import img65 from "../assets/quectionsImages/65.jpg";
import img66 from "../assets/quectionsImages/66.jpg";
import img67 from "../assets/quectionsImages/67.jpg";
import img68 from "../assets/quectionsImages/68.jpg";
import img69 from "../assets/quectionsImages/69.jpg";
import img70 from "../assets/quectionsImages/70.jpg";
import img71 from "../assets/quectionsImages/71.jpg";

export const englishQuiz = {
  totalQuestions: 40,
  score: "Score",
  correctAnswers: "Correct Answers",
  wrongAnswers: "Wrong Answers",
  didntAnswers: "Didn't Answers",
  answerAllQuestions: "Answer all Questions",
  timeRemainings: "Time Remaining",
  total: "Total",
  popTitle: "Are you ready to start the exam?",
  popText: "Click Yes to begin.",
  popButtonText: "Yes, start now!",
  submitBtn: "Submit",
  homeButton: "Home",

  questionArrayFirst: [
    {
      question: "	This road sign indicates	",
      answers: [
        "	No entry on even days	",
        "	No parking on odd days	",
        "	No parking on even days	",
        "	No entry on odd days	",
      ],
      questionId: 1,
      correct: "	No parking on even days	",
      image: img1,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Stop ahead	",
        "	Cross roads ahead	",
        "	Compulsory roundabout	",
        "	Roundabout ahead	",
      ],
      questionId: 2,
      correct: "	Roundabout ahead	",
      image: img2,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Give way to vehicles on the adjacent road	",
        "	Compulsory triangular junction	",
        "	Give way ahead	",
        "	Main road ahead	",
      ],
      questionId: 3,
      correct: "	Give way to vehicles on the adjacent road	",
      image: img3,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Animal sanctuary ahead	",
        "	Cattle farm ahead	",
        "	Cattle crossing ahead	",
        "	Crossing for cattle ahead	",
      ],
      questionId: 4,
      correct: "	Cattle crossing ahead	",
      image: img4,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Double bend to right ahead	",
        "	U-turn ahead	",
        "	Right bend ahead	",
        "	Hair pin bend to right ahead	",
      ],
      questionId: 5,
      correct: "	Hair pin bend to right ahead	",
      image: img5,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Four-way junction ahead	",
        "	Priority road to vehicles on the major road	",
        "	Priority road ahead	",
        "	Junction box	",
      ],
      questionId: 6,
      correct: "	Priority road to vehicles on the major road	",
      image: img6,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Traffic from left merges ahead	",
        "	Staggered junction ahead with first side road to left	",
        "	Priority for traffic on left	",
        "	Y' junction ahead	",
      ],
      questionId: 7,
      correct: "	Traffic from left merges ahead	",
      image: img7,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Crossing for children	",
        "	Blind people crossing ahead	",
        "	Children present/crossing Ahead	",
        "	School ahead	",
      ],
      questionId: 8,
      correct: "	Children present/crossing Ahead	",
      image: img8,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Pedestrian crossing	",
        "	Police station	",
        "	Filling station	",
        "	Parking	",
      ],
      questionId: 9,
      correct: "	Parking	",
      image: img9,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Double bend to right ahead	",
        "	Turn right ahead	",
        "	Right bend ahead	",
        "	Hair pin bend to right ahead	",
      ],
      questionId: 10,
      correct: "	Right bend ahead	",
      image: img10,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	A prohibitory sign	",
        "	A restrictive sign	",
        "	A mandatory sign	",
        "	A danger warning sign	",
      ],
      questionId: 11,
      correct: "	A restrictive sign	",
      image: img11,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Road closed for all vehicles	",
        "	No parking on odd days	",
        "	No parking and standing	",
        "	No parking	",
      ],
      questionId: 12,
      correct: "	No parking	",
      image: img12,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Weight limit on one axle	",
        "	End of speed limit	",
        "	Beginning of speed limit	",
        "	Speed limit	",
      ],
      questionId: 13,
      correct: "	End of speed limit	",
      image: img13,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Street for pedestrians only	",
        "	Give way for pedestrians	",
        "	Pedestrian crossing	",
        "	Pedestrian crossing ahead	",
      ],
      questionId: 14,
      correct: "	Pedestrian crossing	",
      image: img14,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Slippery road ahead	",
        "	Dangerous ascent ahead	",
        "	Slippery road for cars	",
        "	Dangerous descent ahead	",
      ],
      questionId: 15,
      correct: "	Dangerous descent ahead	",
      image: img15,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Y' Junction Ahead	",
        "	Road narrows ahead	",
        "	Junction where vehicles enter the main road from both sides	",
        "	Dual carriage-way starts ahead	",
      ],
      questionId: 16,
      correct: "	Y' Junction Ahead	",
      image: img16,
    },
    {
      question: "	In the junction shown in the image	",
      answers: [
        "	The motor car should drive straight ahead	",
        "	The three wheeler can drive straight ahead	",
        "	The three wheeler can turn right or drive straight ahead.	",
        "	The motor car can drive straight ahead or turn right.	",
      ],
      questionId: 17,
      correct: "	The motor car should drive straight ahead	",
      image: img17,
    },
    {
      question:
        "	To turn left from the junction, you should follow the below steps in order (A,B,C):	",
      answers: [
        "	 Decide, signal, and act	",
        "	Observe, decide, and act	",
        "	Signal, decide, and act	",
        "	All of the above are correct	",
      ],
      questionId: 18,
      correct: "	Observe, decide, and act	",
      image: img18,
    },
    {
      question:
        "	Which colour will be displayed next on the traffic light signal?	",
      answers: ["	Red and amber	", "	Red	", "	Green and amber	", "	Green	"],
      questionId: 19,
      correct: "	Red	",
      image: img19,
    },
    {
      question: "	What's the hand signal displayed by the police officer?	",
      answers: [
        "	Traffic approaching both from front and behind to STOP	",
        "	Traffic approaching from behind to STOP	",
        "	STOP	",
        "	Traffic approaching from the front to STOP	",
      ],
      questionId: 20,
      correct: "	Traffic approaching both from front and behind to STOP	",
      image: img20,
    },
    {
      question: "	This road marking indicates:	",
      answers: [
        "	Marking of the lanes	",
        "	Centre line	",
        "	The line that prohibits turning right or crossing the road	",
        "	The line that prohibits crossing the road	",
      ],
      questionId: 21,
      correct: "	The line that prohibits crossing the road	",
      image: img21,
    },
    {
      question: "	In this junction with traffic light signals:	",
      answers: [
        "	The van can turn left if desired	",
        "	The van should turn right	",
        "	The motor car can turn right	",
        "	The motor car can turn left	",
      ],
      questionId: 22,
      correct: "	The van should turn right	",
      image: img22,
    },
    {
      question: "	What does this horizontal marking on the road indicate?	",
      answers: [
        "	Give-way line	",
        "	The stop line in a place where the road is divided	",
        "	Stop and yield line	",
        "	Give-way line on a roundabout	",
      ],
      questionId: 23,
      correct: "	Give-way line on a roundabout	",
      image: img23,
    },
    {
      question: "	The driver who enters the T junction displayed in the image:	",
      answers: [
        "	Should not stop if there are no vehicles on the main road	",
        "	Should stop the vehicle mandatorily	",
        "	Should stop only if turning to right	",
        "	Should not stop if turning to left	",
      ],
      questionId: 24,
      correct: "	Should stop the vehicle mandatorily	",
      image: img24,
    },
    {
      question: "	What does this horizontal marking on the road indicate?	",
      answers: [
        "	Give-way line	",
        "	Stop line at a traffic light signals or an intersection controlled by the police	",
        "	Stop line at a stop sign	",
        "	Give-way line on a roundabout	",
      ],
      questionId: 25,
      correct: "	Stop line at a stop sign	",
      image: img25,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Road closed ahead	",
        "	Railway level crossing ahead	",
        "	Unprotected level crossing	",
        "	Unprotected level crossing ahead	",
      ],
      questionId: 26,
      correct: "	Railway level crossing ahead	",
      image: img26,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Compulsory roundabout	",
        "	No entry	",
        "	Road closed for lorries and buses	",
        "	Road closed for all vehicles	",
      ],
      questionId: 27,
      correct: "	Road closed for all vehicles	",
      image: img27,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Slippery road ahead	",
        "	Road with bends ahead	",
        "	Dangerous junction ahead	",
        "	Road narrows ahead	",
      ],
      questionId: 28,
      correct: "	Slippery road ahead	",
      image: img28,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Y' Junction Ahead	",
        "	Road narrows ahead	",
        "	Dual carriage-way ends	",
        "	Narrow bridge ahead	",
      ],
      questionId: 29,
      correct: "	Dual carriage-way ends	",
      image: img29,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Compulsory roundabout ahead	",
        "	Compulsory roundabout	",
        "	Roundabout ahead	",
        "	Roundabout	",
      ],
      questionId: 30,
      correct: "	Compulsory roundabout	",
      image: img30,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Turn left and stop	",
        "	Turn left	",
        "	Priority for turning left	",
        "	Turn left ahead	",
      ],
      questionId: 31,
      correct: "	Turn left ahead	",
      image: img31,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Information sign	",
        "	Danger warning sign	",
        "	Prohibitory sign	",
        "	Mandatory sign	",
      ],
      questionId: 32,
      correct: "	Prohibitory sign	",
      image: img32,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Double bend to left ahead	",
        "	Hair pin bend to left ahead	",
        "	Left bend ahead	",
        "	U bend ahead	",
      ],
      questionId: 33,
      correct: "	Hair pin bend to left ahead	",
      image: img33,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Road closed for motor vehicles	",
        "	Road closed	",
        "	Road closed for cars and motorcycles	",
        "	No entry for cars and motorcycles	",
      ],
      questionId: 34,
      correct: "	Road closed for motor vehicles	",
      image: img34,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Roundabout ahead	",
        "	Parking for vehicles	",
        "	Stop	",
        "	Stop ahead	",
      ],
      questionId: 35,
      correct: "	Stop ahead	",
      image: img35,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Dual carriage-way starts ahead	",
        "	Road narrows ahead	",
        "	Dual carriage-way starts end	",
        "	Narrow bridge ahead	",
      ],
      questionId: 36,
      correct: "	Road narrows ahead	",
      image: img36,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Speed limit	",
        "	Normal speed	",
        "	Minimum speed	",
        "	Speed limit for city limits	",
      ],
      questionId: 37,
      correct: "	Speed limit	",
      image: img37,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	Junction ahead	",
        "	No parking and standing on odd days	",
        "	No parking and standing on even days	",
        "	No parking and standing	",
      ],
      questionId: 38,
      correct: "	No parking and standing	",
      image: img38,
    },
    {
      question:
        "	What colour will be displayed next on this traffic light signal?	",
      answers: ["	Green	", "	Amber	", "	Red and amber	", "	Amber and green	"],
      questionId: 39,
      correct: "	Red and amber	",
      image: img39,
    },
    {
      question: "	Road sign indicates	",
      answers: [
        "	End of priority over oncoming traffic vehicles	",
        "	No entry	",
        "	No overtaking	",
        "	No parking on odd days	",
      ],
      questionId: 40,
      correct: "	No overtaking	",
      image: img40,
    },
  ],

  questionArraySecond: [
    {
      question: "	What's the command given by the police officer?	",
      answers: [
        "	Traffic approaching from behind to STOP	",
        "	Traffic approaching both from front and behind to STOP	",
        "	Come forward	",
        "	Stop	",
      ],
      questionId: 1,
      correct: "	Stop	",
      image: img44,
    },
    {
      question: "	What does this horizontal marking on the road indicate?	",
      answers: [
        "	Give-way line	",
        "	Stop line at a traffic light signal or an intersection controlled by the police	",
        "	Stop line at a stop signal	",
        "	Give-way line at a roundabout	",
      ],
      questionId: 2,
      correct:
        "	Stop line at a traffic light signal or an intersection controlled by the police	",
      image: img45,
    },
    {
      question: "	What does this horizontal marking on the road indicate?	",
      answers: [
        "	Stop line at an intersection	",
        "	Give-way line at a roundabout	",
        "	Stop line at a stop sign	",
        "	Give-way line	",
      ],
      questionId: 3,
      correct: "	Give-way line	",
      image: img46,
    },
    {
      question: "	What does this horizontal marking on the road indicate?	",
      answers: [
        "	Lane marking	",
        "	Line prohibiting crossing the road	",
        "	Line prohibiting turning right or crossing the road	",
        "	Centre line	",
      ],
      questionId: 4,
      correct: "	Line prohibiting turning right or crossing the road	",
      image: img47,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Dual carriage-way ahead	",
        "	Narrow bridge	",
        "	Road narrows ahead	",
        "	Narrow bridge ahead	",
      ],
      questionId: 5,
      correct: "	Narrow bridge ahead	",
      image: img48,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Pedestrian crossing ahead	",
        "	Pedestrian crossing	",
        "	Passenger crossing ahead	",
        "	Children crossing ahead	",
      ],
      questionId: 6,
      correct: "	Pedestrian crossing ahead	",
      image: img49,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	No entry on odd days	",
        "	No parking on odd days	",
        "	No entry	",
        "	Traffic cannot come from the opposite direction	",
      ],
      questionId: 7,
      correct: "	No entry	",
      image: img50,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Main road ahead	",
        "	Triangular junction ahead	",
        "	Give way ahead	",
        "	Give way to vehicles on the adjacent road	",
      ],
      questionId: 8,
      correct: "	Give way to vehicles on the adjacent road	",
      image: img51,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Vehicle entrance is limited	",
        "	Give way ahead	",
        "	Priority road	",
        "	End of priority given to vehicles on the major road	",
      ],
      questionId: 9,
      correct: "	End of priority given to vehicles on the major road	",
      image: img52,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Entry for main road ahead	",
        "	Priority for the oncoming traffic	",
        "	Priority over oncoming traffic vehicles	",
        "	Beware of oncoming vehicles	",
      ],
      questionId: 10,
      correct: "	Priority over oncoming traffic vehicles	",
      image: img53,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Beware of the oncoming train	",
        "	Beware of the train	",
        "	Unprotected level crossing	",
        "	Unprotected level crossing ahead	",
      ],
      questionId: 11,
      correct: "	Unprotected level crossing ahead	",
      image: img54,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	End of road ahead	",
        "	Hair pin bend to right ahead	",
        "	No U-turn	",
        "	No right turn	",
      ],
      questionId: 12,
      correct: "	No U-turn	",
      image: img55,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Roundabout ahead	",
        "	Traffic light signal	",
        "	Stop	",
        "	Give way to vehicles on the adjacent road	",
      ],
      questionId: 13,
      correct: "	Stop	",
      image: img56,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Entering city limits	",
        "	The maximum speed for driving in that area	",
        "	The normal speed for driving in that area	",
        "	End of speed limit	",
      ],
      questionId: 14,
      correct: "	The maximum speed for driving in that area	",
      image: img57,
    },
    {
      question: "	This sign	",
      answers: [
        "	Prohibitory signs	",
        "	Danger warning signs	",
        "	Control signals	",
        "	Information sign	",
      ],
      questionId: 15,
      correct: "	Information sign	",
      image: img58,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Railway crossing ahead	",
        "	Hospital ahead	",
        "	Church ahead	",
        "	Cross road ahead	",
      ],
      questionId: 16,
      correct: "	Cross road ahead	",
      image: img59,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Turn right	",
        "	Left bend ahead	",
        "	Right bend ahead	",
        "	Turn left ahead	",
      ],
      questionId: 17,
      correct: "	Left bend ahead	",
      image: img60,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Y' junction ahead	",
        "	Main road ahead	",
        "	Priority over  traffic coming on the left side	",
        "	Traffic from left merges ahead	",
      ],
      questionId: 18,
      correct: "	Traffic from left merges ahead	",
      image: img61,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Turn right ahead	",
        "	Hair pin bend to right ahead	",
        "	U turn ahead	",
        "	Hair pin bend to left ahead	",
      ],
      questionId: 19,
      correct: "	Hair pin bend to right ahead	",
      image: img62,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	No parking on odd days	",
        "	Road closed	",
        "	No parking	",
        "	Roundabout ahead	",
      ],
      questionId: 20,
      correct: "	No parking	",
      image: img63,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	No parking on even days	",
        "	No entry for vehicles	",
        "	No parking	",
        "	No parking and standing	",
      ],
      questionId: 21,
      correct: "	No parking and standing	",
      image: img64,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Turn back ahead	",
        "	Turn left	",
        "	Pass left side	",
        "	Turn left ahead	",
      ],
      questionId: 22,
      correct: "	Pass left side	",
      image: img65,
    },
    {
      question: "	This road sign indicates	",
      answers: [
        "	Turn right ahead	",
        "	Parking for vehicles	",
        "	Danger! Slow down	",
        "	STOP sign	",
      ],
      questionId: 23,
      correct: "	Turn right ahead	",
      image: img66,
    },
    {
      question:
        "	According to the diagram, when turning a vehicle at a junction at the අ position	",
      answers: [
        "	Vehicle should be stopped	",
        "	Signals should be turned on	",
        "	Driver should look at the mirror	",
        "	Speed should be decreased	",
      ],
      questionId: 24,
      correct: "	Driver should look at the mirror	",
      image: img67,
    },
    {
      question:
        "	 According to the diagram, when turning a vehicle at a junction at the “ආ” position	",
      answers: [
        "	Horn should be beeped	",
        "	Driver should look at the mirror	",
        "	Signals should be displayed	",
        "	Speed must be increased	",
      ],
      questionId: 25,
      correct: "	Signals should be displayed	",
      image: img68,
    },
    {
      question:
        "	 According to the diagram, when turning a vehicle at a junction, at the “ඇ” position	",
      answers: [
        "	Horn should be beeped	",
        "	Driver should look at the mirror	",
        "	Signals should be displayed	",
        "	The vehicle must be turned into the right lane	",
      ],
      questionId: 26,
      correct: "	The vehicle must be turned into the right lane	",
      image: img69,
    },
    {
      question: "	According to the diagram, the broken line at “A” indicates, 	",
      answers: [
        "	There is an intersection ahead	",
        "	The line prohibiting crossing the road	",
        "	The centre line marking the middle of the road	",
        "	Line marking the lanes	",
      ],
      questionId: 27,
      correct: "	The centre line marking the middle of the road	",
      image: img70,
    },
    {
      question: "	According to the diagram, the broken line at “B” indicates, 	",
      answers: [
        "	The line that divides the road.	",
        "	Stop line that indicates STOP	",
        "	Give-way line	",
        "	Crossing for pedestrians	",
      ],
      questionId: 28,
      correct: "	Give-way line	",
      image: img71,
    },
    {
      question:
        "	In Sri Lankan road signs, danger warning signs are indicated in 	",
      answers: [
        "	White in blue background	",
        "	White in red background	",
        "	Black in yellow background	",
        "	Red in white background	",
      ],
      questionId: 29,
      correct: "	Black in yellow background	",
      image: "",
    },
    {
      question: "	The basic rule in a roundabout is	",
      answers: [
        "	Must give priority to vehicles from the right	",
        "	Give way for traffic coming from the left	",
        "	Give way for vehicles coming from a direction of traffic congestion	",
        "	Give way for vehicles coming from the left or right	",
      ],
      questionId: 30,
      correct: "	Must give priority to vehicles from the right	",
      image: "",
    },
    {
      question:
        "	When your vehicle is moving forward on a road with three lanes in one direction, the lanes you should choose are:	",
      answers: [
        "	Middle lane or right lane	",
        "	Right lane	",
        "	Left lane	",
        "	Middle lane	",
      ],
      questionId: 31,
      correct: "	Middle lane	",
      image: "",
    },
    {
      question:
        "	You should keep an appropriate distance between your vehicle and the vehicle in front of you because	",
      answers: [
        "	If the driver of the vehicle in front of you suddenly stops, you will have more time to pass the vehicle.	",
        "	 If the driver of the vehicle in front of you suddenly stops, you will be able to stop your vehicle safely.	",
        "	You have more room to overtake the vehicle in front of you.	",
        "	It is easier to drive behind the vehicle in front of you.	",
      ],
      questionId: 32,
      correct:
        "	 If the driver of the vehicle in front of you suddenly stops, you will be able to stop your vehicle safely.	",
      image: "",
    },
    {
      question: "	Hazard lamps of a vehicle should be used	",
      answers: [
        "	When the vehicle has broken down and parked	",
        "	To indicate that the vehicle is driving due to an emergency.	",
        "	When the vehicle is going to drive straight from a junction	",
        "	To take priority	",
      ],
      questionId: 33,
      correct: "	When the vehicle has broken down and parked	",
      image: "",
    },
    {
      question: "	  When another vehicle overtakes your vehicle,	",
      answers: [
        "	 If you don’t' want the other vehicle to over take you, signal to the right	",
        "	Put your vehicle in a lower gear and prepare to park the vehicle	",
        "	You must allow the  other vehicle to pass your vehicle without increasing the speed	",
        "	If you don’t' want the other vehicle to over take you, increase the speed.	",
      ],
      questionId: 34,
      correct:
        "	You must allow the  other vehicle to pass your vehicle without increasing the speed	",
      image: "",
    },
    {
      question: "	At a junction box, 	",
      answers: [
        "	You can enter the junction box, if you proceed straight ahead	",
        "	Vehicles should not enter a junction box if the exit is blocked unless in the case of turning right	",
        "	A vehicle can enter a junction box at any occasion except when turning right	",
        "	You can enter a junction box in any occasion.	",
      ],
      questionId: 35,
      correct:
        "	Vehicles should not enter a junction box if the exit is blocked unless in the case of turning right	",
      image: "",
    },
    {
      question: "	A vehicle can be parked	",
      answers: [
        "	25m away from a junction	",
        "	20 m away from a bus stop	",
        "	At the  spiral perimeter of a pedestrian crossing or within 10 m of it	",
        "	0m away from a fire hose	",
      ],
      questionId: 36,
      correct: "	25m away from a junction	",
      image: "",
    },
    {
      question: "	You shouldn't overtake another vehicle	",
      answers: [
        "	In front of a hospital or when reaching a hospital	",
        "	In front of a Court house or when reaching it.	",
        "	In such a way to avoid crossing the broken lines marked on the road.	",
        "	In a pedestrian crossing or when reaching it	",
      ],
      questionId: 37,
      correct: "	In a pedestrian crossing or when reaching it	",
      image: "",
    },
    {
      question: "	If your vehicle is involved in a road accident,	",
      answers: [
        "	The vehicle should be taken off the road as soon as possible and reported to the police	",
        "	The position of the vehicle can be marked on the road and the vehicle can be used to transport the injured	",
        "	The vehicle involved in the accident should not be moved until a police office arrives	",
        "	The vehicle should be taken off the road as soon as possible and checked for technical faults.	",
      ],
      questionId: 38,
      correct:
        "	The position of the vehicle can be marked on the road and the vehicle can be used to transport the injured	",
      image: "",
    },
    {
      question: "	At night, you should always park your vehicle 	",
      answers: [
        "	Facing the direction where traffic is light	",
        "	In the opposite direction of the traffic.	",
        "	Facing the direction of heavy traffic	",
        "	Facing the direction of the traffic	",
      ],
      questionId: 39,
      correct: "	Facing the direction of the traffic	",
      image: "",
    },
    {
      question: "	You can overtake a vehicle on the left	",
      answers: [
        "	When the vehicle in front of you is signalling to turn right	",
        "	When the vehicle in front of you is driving at a very low speed.	",
        "	When you feel that overtaking on the right is not safe.	",
        "	When there is lot of traffic in the opposite direction.	",
      ],
      questionId: 40,
      correct: "	When the vehicle in front of you is signalling to turn right	",
      image: "",
    },
  ],

  questionArrayThird: [
    {
      question: "	You should not over take another vehicle	",
      answers: [
        "	In front of a court house	",
        "	When there are broken lines marked on the road	",
        "	In a dangerous bend	",
        "	In front of a hospital	",
      ],
      questionId: 1,
      correct: "	In a dangerous bend	",
      image: "",
    },
    {
      question: "	The use of the expressway is not be prohibited for	",
      answers: ["	Three-wheelers	", "	Land vehicles	", "	Motorcycles	", "	Lorries	"],
      questionId: 2,
      correct: "	Lorries	",
      image: "",
    },
    {
      question:
        "	What's the first step to take if you intend to overtake a vehicle.	",
      answers: [
        "	Get the vehicle in the right lane	",
        "	Signalling	",
        "	Observation	",
        "	Control the speed according to the situation	",
      ],
      questionId: 3,
      correct: "	Observation	",
      image: "",
    },
    {
      question: "	When stopping a vehicle, the stopping distance is	",
      answers: [
        "	The lowest distance from thinking distance and braking distance	",
        "	The difference between thinking distance and braking distance	",
        "	The addition of thinking distance and braking distance	",
        "	The highest distance from thinking distance and braking distance	",
      ],
      questionId: 4,
      correct: "	The addition of thinking distance and braking distance	",
      image: "",
    },
    {
      question:
        "	When you drive along the road and see a road sign indicating 'pedestrian crossing ahead'	",
      answers: [
        "	 If there is no traffic congestion on the road, you can drive as you want	",
        "	Increase the speed to pass the pedestrian crossing before pedestrians arrive.	",
        "	Control the speed with the aim to stop the vehicle	",
        "	Beep the horn to let pedestrians know you are coming and drive without stopping	",
      ],
      questionId: 5,
      correct: "	Control the speed with the aim to stop the vehicle	",
      image: "",
    },
    {
      question: "	If you want to overtake the vehicle in front of you	",
      answers: [
        "	When the driver of the vehicle in front you signals for overtaking, you shouldn't do it.	",
        "	Check whether the road is clear ahead	",
        "	Look for space for overtaking	",
        "	Observe the road well and determine if the vehicle can be overtaken.	",
      ],
      questionId: 6,
      correct:
        "	Observe the road well and determine if the vehicle can be overtaken.	",
      image: "",
    },
    {
      question: "	 If you have to turn right ahead, you must drive	",
      answers: [
        "	In any lane you prefer	",
        "	Left lane on the road	",
        "	Take the vehicle to the right	",
        "	The lane closest to the centre line	",
      ],
      questionId: 7,
      correct: "	Take the vehicle to the right	",
      image: "",
    },
    {
      question:
        "	 If you know that you have to stop on the left ahead, you should drive the vehicle, 	",
      answers: [
        "	In the left lane	",
        "	No need to use the lanes	",
        "	In the centre lane	",
        "	Right side on the road	",
      ],
      questionId: 8,
      correct: "	In the left lane	",
      image: "",
    },
    {
      question: "	When you enter the main road from a side road	",
      answers: [
        "	You can enter from the left in the absence of heavy traffic on the road	",
        "	Observe the right side and enter if there are no vehicles	",
        "	Observe the left side and enter if there are no vehicles	",
        "	It is advisable to enter the road after successfully observing both sides of the road.	",
      ],
      questionId: 9,
      correct:
        "	It is advisable to enter the road after successfully observing both sides of the road.	",
      image: "",
    },
    {
      question:
        "	What should you do if the vehicle behind you wants to overtake you	",
      answers: [
        "	There is no need to be concerned about the vehicle behind you.	",
        "	Decrease the speed of the vehicle and let the other vehicle over take from the left	",
        "	Turn on the left signal lights and let the driver know he can overtake you	",
        "	Take the vehicle to the centre of the road and do not let him overtake.	",
      ],
      questionId: 10,
      correct:
        "	Decrease the speed of the vehicle and let the other vehicle over take from the left	",
      image: "",
    },
    {
      question: "	If your phone rings while driving	",
      answers: [
        "	You cannot answer the phone only in urban areas.	",
        "	Hold the steering wheel with one hand and answer the phone with the other hand.	",
        "	 If you want to respond to the call, pull over to a safe location, stop the vehicle, and answer the call. 	",
        "	You shouldn't carry a mobile phone with you.	",
      ],
      questionId: 11,
      correct:
        "	 If you want to respond to the call, pull over to a safe location, stop the vehicle, and answer the call. 	",
      image: "",
    },
    {
      question:
        "	What should you do when you are driving and encounter  a line of vehicles parked one after the other due to a barrier in front of the road?	",
      answers: [
        "	If there are no vehicles on the opposite direction, you can drive from the right side of the road. 	",
        "	Pass all the vehicles in the line and reach the barrier.	",
        "	Stop behind the last vehicle parked on the road	",
        "	Beep the horn to let others know that you are in a hurry	",
      ],
      questionId: 12,
      correct: "	Stop behind the last vehicle parked on the road	",
      image: "",
    },
    {
      question:
        "	What should you do when a bus is  stopped in front and passengers are getting off?	",
      answers: [
        "	There is no need to be concerned about stopped vehicles.	",
        "	Overtake the bus before pedestrians cross the road.	",
        "	Stop the vehicle behind the bus	",
        "	Decrease the speed in order to be able to stop the vehicle when pedestrians cross the road.	",
      ],
      questionId: 13,
      correct:
        "	Decrease the speed in order to be able to stop the vehicle when pedestrians cross the road.	",
      image: "",
    },
    {
      question:
        "	When you know that there is a junction with traffic lights ahead,	",
      answers: [
        "	Increase the speed and pass the junction as soon as possible	",
        "	Always drive while obeying the traffic light signals	",
        "	Get ready to stop at the junction	",
        "	Follow the actions of vehicles in front of you	",
      ],
      questionId: 14,
      correct: "	Always drive while obeying the traffic light signals	",
      image: "",
    },
    {
      question: "	When driving a vehicle at night	",
      answers: [
        "	Use the vehicle's horn as much as possible	",
        "	You can increase the speed since there is less traffic	",
        "	Drive more carefully as vision is limited	",
        "	There is no need to worry when the headlights are turned on	",
      ],
      questionId: 15,
      correct: "	Drive more carefully as vision is limited	",
      image: "",
    },
    {
      question:
        "	If a police officer is controlling traffic at a junction with traffic light signals, you should 	",
      answers: [
        "	Obey the commands of the police officer only if you are turning right	",
        "	Obey the commands of the police officer	",
        "	Obey the commands of the traffic light signals	",
        "	Drive as you want	",
      ],
      questionId: 16,
      correct: "	Obey the commands of the police officer	",
      image: "",
    },
    {
      question:
        "	 If your vehicle meets with an accident/ if your vehicle breaks down while driving, what should you do? 	",
      answers: [
        "	Stop the vehicle while trying your best to not block the road.	",
        "	Stop the vehicle and turn on the lights	",
        "	Stop where you are	",
        "	Drive as far as possible	",
      ],
      questionId: 17,
      correct: "	Stop the vehicle while trying your best to not block the road.	",
      image: "",
    },
    {
      question: "	When driving in rainy weather, you should always	",
      answers: [
        "	When the tyres are in good condition, there is no need for concern	",
        "	Drive slowly in low gear	",
        "	Drive fast in high gear	",
        "	Drive slow in a high gear	",
      ],
      questionId: 18,
      correct: "	Drive slowly in low gear	",
      image: "",
    },
    {
      question: "	When driving on slopes	",
      answers: [
        "	Drive as fast as possible	",
        "	Drive in a low gear	",
        "	Drive in a high gear	",
        "	Drive in neutral gear	",
      ],
      questionId: 19,
      correct: "	Drive in a low gear	",
      image: "",
    },
    {
      question: "	 When you are parking the vehicle in a flat ground,	",
      answers: [
        "	Lock the vehicle properly	",
        "	Apply the parking brakes properly	",
        "	Turn on the lights even during the day	",
        "	Park with the front wheels turned to the right	",
      ],
      questionId: 20,
      correct: "	Apply the parking brakes properly	",
      image: "",
    },
    {
      question: "	Hazard lights should be turned on	",
      answers: [
        "	When transporting patients	",
        "	When driving in a four-way intersection	",
        "	When overtaking in a dangerous place	",
        "	When a vehicle is parked on the road after an accident	",
      ],
      questionId: 21,
      correct: "	When a vehicle is parked on the road after an accident	",
      image: "",
    },
    {
      question: "	When refuelling a motor vehicle	",
      answers: [
        "	No need to be concerned about the engine while fuelling.	",
        "	The vehicle must be fuelled while the engine is running	",
        "	The engine should be stopped while fuelling	",
        "	Check whether engine fuel is at the required level	",
      ],
      questionId: 22,
      correct: "	The engine should be stopped while fuelling	",
      image: "",
    },
    {
      question:
        "	Vehicle Identity Card (VIC) issued under the new number plate system	",
      answers: [
        "	There is no need to be concerned about the VIC	",
        "	Should be always kept inside the vehicle	",
        "	Should be kept in a safe place at home	",
        "	Should be kept with the owner of the vehicle	",
      ],
      questionId: 23,
      correct: "	Should be always kept inside the vehicle	",
      image: "",
    },
    {
      question:
        "	When you are driving at night, and another vehicle is approaching from the front, you should 	",
      answers: [
        "	Dim the headlights only if the oncoming vehicle dims its headlight	",
        "	Dim the headlights of the vehicle	",
        "	Turn off the headlights of the vehicle	",
        "	Beep the horn	",
      ],
      questionId: 24,
      correct: "	Dim the headlights of the vehicle	",
      image: "",
    },
    {
      question: "	What are the steps to follow when overtaking a vehicle	",
      answers: [
        "	Get more power by choosing a high gear like 3, 4	",
        "	Get more speed by choosing a high gear like 3, 4	",
        "	Get more power by choosing a low gear like 1 or 2	",
        "	Get lower power by choosing a low gear like 1 or 2	",
      ],
      questionId: 25,
      correct: "	Get more power by choosing a high gear like 3, 4	",
      image: "",
    },
    {
      question: "	The third lane in a three-lane road should be used to	",
      answers: [
        "	Turn right	",
        "	For high speed driving	",
        "	To overtake another vehicle	",
        "	 To overtake another vehicle, to turn right, or to avoid an accident 	",
      ],
      questionId: 26,
      correct:
        "	 To overtake another vehicle, to turn right, or to avoid an accident 	",
      image: "",
    },
    {
      question:
        "	 According to the Traffic Ordinance, the colours of a danger warning sign are	",
      answers: [
        "	 Red, white, and black	",
        "	Red, white, and blue	",
        "	Red in white background	",
        "	Black in yellow background	",
      ],
      questionId: 27,
      correct: "	Black in yellow background	",
      image: "",
    },
    {
      question: "	When entering the main road, you should give way to	",
      answers: [
        "	Vehicles coming from the left	",
        "	Vehicles coming from the right	",
        "	Vehicles coming from the right and left	",
        "	Oncoming vehicles	",
      ],
      questionId: 28,
      correct: "	Vehicles coming from the right and left	",
      image: "",
    },
    {
      question:
        "	 When driving at night, if the oncoming vehicle doesn't dim the headlights, you should	",
      answers: [
        "	Turn off the lights in the vehicle	",
        "	Immediately increase the brightness of the headlights	",
        "	Decrease the speed of the vehicle	",
        "	Slow down or stop the vehicle	",
      ],
      questionId: 29,
      correct: "	Slow down or stop the vehicle	",
      image: "",
    },
    {
      question:
        "	When there is a white longitudinal line marked at the centre of the road	",
      answers: [
        "	Can cross the line to turn right, cannot overtake 	",
        "	Must cross the line and turn right	",
        "	Can cross the line to turn right or overtake	",
        "	Can cross the line to overtake, cannot turn right	",
      ],
      questionId: 30,
      correct: "	Can cross the line to turn right, cannot overtake 	",
      image: "",
    },
    {
      question: "	The course of action to follow when reversing a vehicle:	",
      answers: [
        "	Give more power by choosing a higher gear	",
        "	Vehicle can be reversed as you wish	",
        "	Stop the vehicle parallel to the edge of the road and drive backwards using the shortest distance in the by-road	",
        "	Stop the vehicle not parallel to the edge of the road, and drive backwards using the shortest distance 	",
      ],
      questionId: 31,
      correct:
        "	Stop the vehicle parallel to the edge of the road and drive backwards using the shortest distance in the by-road	",
      image: "",
    },
    {
      question: "	Overtaking should be avoided	",
      answers: [
        "	When there is a school ahead	",
        "	When there is a hospital ahead	",
        "	When there are white broken line marked at the centre of the road	",
        "	At the top of a mountain	",
      ],
      questionId: 32,
      correct: "	At the top of a mountain	",
      image: "",
    },
    {
      question: "	When you see the road sign indicating a pedestrian crossing	",
      answers: [
        "	Pay attention only when there is heavy traffic	",
        "	Check whether there is a pedestrian	",
        "	Stop the vehicle before the crossing	",
        "	Slow down the vehicle	",
      ],
      questionId: 33,
      correct: "	Slow down the vehicle	",
      image: "",
    },
    {
      question:
        "	If you experience difficulty in changing gears while driving, the most likely cause would be 	",
      answers: [
        "	There is a fault in the gear lever of the vehicle	",
        "	The clutch is not working properly	",
        "	Engine speed is not good enough	",
        "	There is a fault in the gear box	",
      ],
      questionId: 34,
      correct: "	The clutch is not working properly	",
      image: "",
    },
    {
      question: "	If the traffic lights turn amber when you reach a roundabout	",
      answers: [
        "	It's not controlled by traffic light signals	",
        "	Make way for traffic coming from the right	",
        "	Make way for traffic coming from the right	",
        "	Obey the traffic light signal	",
      ],
      questionId: 35,
      correct: "	Obey the traffic light signal	",
      image: "",
    },
    {
      question:
        "	If the traffic signal is a single amber flashing light when you approach a roundabout	",
      answers: [
        "	It's done to direct your attention to the roundabout	",
        "	Drive straight ahead	",
        "	Follow the rule of the roundabout	",
        "	The rule of the roundabout does not apply there	",
      ],
      questionId: 36,
      correct: "	Follow the rule of the roundabout	",
      image: "",
    },
    {
      question: "	The current regulation regarding tinted windows in vehicles:	",
      answers: [
        "	All glasses except the windscreen can be tinted	",
        "	All glasses cannot be tinted	",
        "	Shutters in the two front doors and 4/5th of the lower part of front windscreen cannot be tinted.	",
        "	All glasses can be tinted	",
      ],
      questionId: 37,
      correct:
        "	Shutters in the two front doors and 4/5th of the lower part of front windscreen cannot be tinted.	",
      image: "",
    },
    {
      question: "	What comes after green traffic light signal?	",
      answers: [
        "	 Green disappears, red and amber appear together	",
        "	Amber appears while green remains	",
        "	Green disappears and red appears	",
        "	Green disappears and amber appears	",
      ],
      questionId: 38,
      correct: "	Green disappears and amber appears	",
      image: "",
    },
    {
      question: "	What comes after the red traffic light signal?	",
      answers: [
        "	Red disappears, amber and green appear together	",
        "	Red disappears and amber appears	",
        "	amber appears while red remains	",
        "	Red disappears and green disappears	",
      ],
      questionId: 39,
      correct: "	amber appears while red remains	",
      image: "",
    },
    {
      question:
        "	Maximum grace period for reporting an accident to the police station is	",
      answers: ["	36 hours	", "	12 hours	", "	24 hours	", "	48 hours	"],
      questionId: 40,
      correct: "	24 hours	",
      image: "",
    },
  ],

  questionArrayFourth: [
    {
      question:
        "	What's the foot position in a manual vehicle that is moving in a normal flat ground (non-traffic area)?	",
      answers: [
        "	Left foot on the clutch pedal and right foot on the accelerator pedal	",
        "	Left foot on the ground pedal and right foot on the accelerator pedal	",
        "	Left foot on the clutch pedal and right foot on the accelerator	",
        "	Left foot slightly on the clutch pedal and right foot on the accelerator	",
      ],
      questionId: 1,
      correct:
        "	Left foot on the ground pedal and right foot on the accelerator pedal	",
      image: "",
    },
    {
      question:
        "	What's the foot position in a manual vehicle that is driven in a traffic congestion?	",
      answers: [
        "	Left foot on the ground pedal and right foot on the brake pedal	",
        "	Left foot on the clutch pedal and right foot slightly on the brake pedal	",
        "	Left foot on the ground pedal and right foot on the accelerator pedal	",
        "	Left foot slightly on the clutch  pedal and right foot on the accelerator	",
      ],
      questionId: 2,
      correct:
        "	Left foot slightly on the clutch  pedal and right foot on the accelerator	",
      image: "",
    },
    {
      question: "	The correct method to overtake a vehicle:	",
      answers: [
        "	The method varies according to the speed of the vehicle	",
        "	 Turn on the signals, observe the road well, and control the vehicle correctly	",
        "	Accelerate the vehicle, turn on the signals, observe the road well, and control the vehicle correctly 	",
        "	Observe the road well, turn on the signals, and control the vehicle correctly	",
      ],
      questionId: 3,
      correct:
        "	Observe the road well, turn on the signals, and control the vehicle correctly	",
      image: "",
    },
    {
      question: "	The first thing to do when overtaking a vehicle:	",
      answers: [
        "	Move the vehicle to the correct lane.	",
        "	Adjust your speed to the right level	",
        "	Signal	",
        "	Look in the rear-view mirror	",
      ],
      questionId: 4,
      correct: "	Look in the rear-view mirror	",
      image: "",
    },
    {
      question:
        "	 When you are preparing to overtake a vehicle, but feel that another vehicle is going to overtake you, the first step to take is,	",
      answers: [
        "	There is no need to pay attention to vehicles behind you	",
        "	Immediately signal very clearly that you are going to overtake	",
        "	Immediately signal the vehicle to decrease its speed	",
        "	Signal very clearly and immediately that you are ready to move to the left	",
      ],
      questionId: 5,
      correct:
        "	Signal very clearly and immediately that you are ready to move to the left	",
      image: "",
    },
    {
      question: "	If there is a yellow longitudinal line along the pavement	",
      answers: [
        "	It's the  line showing the edge of the road	",
        "	Vehicles can be parked in that location	",
        "	Vehicles cannot be parked in that location	",
        "	Vehicles can be parked in such a way that two left wheels can touch the pavement	",
      ],
      questionId: 6,
      correct: "	Vehicles cannot be parked in that location	",
      image: "",
    },
    {
      question: "	If there is a white longitudinal line on the pavement	",
      answers: [
        "	It's the  line showing the edge of the road	",
        "	Vehicles can be parked in that location	",
        "	Vehicles cannot be parked in that location	",
        "	Vehicles can be parked in such a way that two left wheels can touch the pavement	",
      ],
      questionId: 7,
      correct: "	Vehicles can be parked in that location	",
      image: "",
    },
    {
      question: "	The maximum speed limits allowed within city limits	",
      answers: [
        "	50 km/h for all vehicles	",
        "	50 m/h, 50 m/h,  and 40 m/h for motorcycle, car, two-wheeler, commercial vehicle, and three-wheeler, respectively	",
        "	70 km/h for motorcycle, car, and two-wheelers, 60 km/h for commercial vehicles, and 40 km/h for three-wheeler	",
        "	50 km/h for motorcycle, car, and two-wheeler, 50 km/h for commercial vehicle, and 40 km/h for three-wheeler	",
      ],
      questionId: 8,
      correct:
        "	50 km/h for motorcycle, car, and two-wheeler, 50 km/h for commercial vehicle, and 40 km/h for three-wheeler	",
      image: "",
    },
    {
      question: "	When there is a pedestrian crossing	",
      answers: [
        "	Pedestrians can only cross the road using the pedestrian crossing	",
        "	Pedestrians shall cross that road at any other place within a distance of 50 metres from that crossing	",
        "	No pedestrians should cross that road at any other place within a distance of 50 metres from that crossing	",
        "	Pedestrians can cross that road at any other place within a distance of 10 metres from that crossing	",
      ],
      questionId: 9,
      correct:
        "	No pedestrians should cross that road at any other place within a distance of 50 metres from that crossing	",
      image: "",
    },
    {
      question: "	If a traffic light signal is displaying an amber colour light	",
      answers: [
        "	Drive fast before the red light appears	",
        "	Get ready to go since the green light will appear next	",
        "	You should stop as the red light will appear next	",
        "	Go ahead as the next light will be green	",
      ],
      questionId: 10,
      correct: "	You should stop as the red light will appear next	",
      image: "",
    },
    {
      question:
        "	 When there is a bend where you cannot see ahead in the road driving, you should	",
      answers: [
        "	Always keep the vehicle to the centre of the road	",
        "	Don't change the speed, but beep your horn	",
        "	Slow down with the aim to stop and beep the horn	",
        "	Drive fast without beeping the horn	",
      ],
      questionId: 11,
      correct: "	Slow down with the aim to stop and beep the horn	",
      image: "",
    },
    {
      question: "	When there is a single white line on the centre of the road	",
      answers: [
        "	It indicates the danger of a bend	",
        "	It's prohibited to cross the white line and overtake	",
        "	No right turns	",
        "	The line is used to divide the road into lanes	",
      ],
      questionId: 12,
      correct: "	It's prohibited to cross the white line and overtake	",
      image: "",
    },
    {
      question: "	When there are 2 white lines on the centre of the road	",
      answers: [
        "	No overtaking or turning right while crossing the line	",
        "	Can turn right	",
        "	Can overtake	",
        "	Can turn right and overtake	",
      ],
      questionId: 13,
      correct: "	No overtaking or turning right while crossing the line	",
      image: "",
    },
    {
      question:
        "	Installing a metal safety bar on the front buffer of a motor vehicle	",
      answers: [
        "	The front buffer may get loose	",
        "	It's not legal	",
        "	Increases the damage to the other party in case of an accident	",
        "	Disrupts the driver's vision	",
      ],
      questionId: 14,
      correct: "	It's not legal	",
      image: "",
    },
    {
      question:
        "	A driver always keeps his driver's license with him/her while driving:	",
      answers: [
        "	It's not required by law	",
        "	It's required by the law	",
        "	It is sufficient to keep a photocopy.	",
        "	It's safer to keep it at home and present it only when necessary	",
      ],
      questionId: 15,
      correct: "	It's required by the law	",
      image: "",
    },
    {
      question: "	You can overtake a vehicle from the left side:	",
      answers: [
        "	Overtaking from the left is completely prohibited	",
        "	When the driver in front of you has signalled that he is turning right	",
        "	When you feel that it's safer to overtake from the left	",
        "	Can overtake from the left in all of the above scenarios	",
      ],
      questionId: 16,
      correct:
        "	When the driver in front of you has signalled that he is turning right	",
      image: "",
    },
    {
      question: "	An important thing to do when parking a vehicle:	",
      answers: [
        "	Locking the doors properly	",
        "	Apply the parking brakes properly	",
        "	Turn on the parking lights even during the day	",
        "	Turn the front wheels completely to the left and park	",
      ],
      questionId: 17,
      correct: "	Apply the parking brakes properly	",
      image: "",
    },
    {
      question: "	Hazard lights in a vehicle should be turned on	",
      answers: [
        "	When transporting a patient	",
        "	When going straight ahead at a four-way junction	",
        "	When overtaking a vehicle in a dangerous place	",
        "	Only when a vehicle is involved in an accident and parked on the road.	",
      ],
      questionId: 18,
      correct:
        "	Only when a vehicle is involved in an accident and parked on the road.	",
      image: "",
    },
    {
      question: "	When a motor vehicle is being fuelled	",
      answers: [
        "	There is no need to concerned about the engine of the vehicle	",
        "	The vehicle must be fuelled while the engine is running	",
        "	The engine should be turned off while fuelling	",
        "	It must be checked whether the engine oil is at the required level	",
      ],
      questionId: 19,
      correct: "	The engine should be turned off while fuelling	",
      image: "",
    },
    {
      question: "	The rule to follow in a roundabout is	",
      answers: [
        "	Give way to traffic coming from left and right	",
        "	Give priority to traffic coming from the left	",
        "	If traffic is not controlled by a police officer, give way to vehicles coming from the right	",
        "	Give way to traffic coming from the front and right	",
      ],
      questionId: 20,
      correct:
        "	If traffic is not controlled by a police officer, give way to vehicles coming from the right	",
      image: "",
    },
    {
      question: "	When the air pressure in the tyre is too low	",
      answers: [
        "	The tyre will wear out quickly in some places	",
        "	Two sides of the tyre will wear out quickly	",
        "	The tyre will wear out quickly	",
        "	The middle of the tyre will wear out	",
      ],
      questionId: 21,
      correct: "	Two sides of the tyre will wear out quickly	",
      image: "",
    },
    {
      question: "	What's more likely to cause high fuel consumption?	",
      answers: [
        "	Driving in high gear	",
        "	Poor steering control	",
        "	Accelerating at bends	",
        "	Harsh braking and accelerating	",
      ],
      questionId: 22,
      correct: "	Harsh braking and accelerating	",
      image: "",
    },
    {
      question:
        "	What is more likely to cause difficulty in changing gears in a vehicle with a manual gearbox?	",
      answers: [
        "	Clutch is not working properly	",
        "	The wheels on the gearbox are broken	",
        "	Engine does not run smoothly	",
        "	Vehicle diffuser is faulty	",
      ],
      questionId: 23,
      correct: "	Clutch is not working properly	",
      image: "",
    },
    {
      question:
        "	The first thing you should do when the battery light turns on to indicate that the battery is not charging	",
      answers: [
        "	Check battery wires	",
        "	Check the battery	",
        "	Check the belt for turning the alternator and dynamo	",
        "	Check the vehicle's electrical system	",
      ],
      questionId: 24,
      correct: "	Check the belt for turning the alternator and dynamo	",
      image: "",
    },
    {
      question:
        "	  If one or more wheel indicate non-uniform sudden braking, this indicates	",
      answers: [
        "	An oil leak in the brake system	",
        "	Faulty brake drum disc or liner	",
        "	Excessive free movement of the brake pedal	",
        "	Faulty brake power system	",
      ],
      questionId: 25,
      correct: "	Faulty brake drum disc or liner	",
      image: "",
    },
    {
      question:
        "	  If the middle of the tyre has worn out more than the sides, it indicates X	",
      answers: [
        "	Faulty suspension system	",
        "	Air pressure in the tyre is too low	",
        "	Air pressure in the tyre is too high	",
        "	Faulty steering system	",
      ],
      questionId: 26,
      correct: "	Air pressure in the tyre is too high	",
      image: "",
    },
    {
      question: "	Lubricants are used for engines	",
      answers: [
        "	To increase the speed of air circulation in the engine	",
        "	To reduce wear and tear of moving parts	",
        "	To increase the efficiency of fuel consumption	",
        "	To increase the efficiency of the engine	",
      ],
      questionId: 27,
      correct: "	To reduce wear and tear of moving parts	",
      image: "",
    },
    {
      question: "	What's the function of a differential?	",
      answers: [
        "	Maintain the same speed in tyres when the vehicle is driving in a bend	",
        "	Enable carrying more weight without damaging the tyres	",
        "	Reduce uneven wearing off of tyres	",
        "	Change the speed in tyres when driving in a bend	",
      ],
      questionId: 28,
      correct: "	Change the speed in tyres when driving in a bend	",
      image: "",
    },
    {
      question: "	What causes front vibrations while driving?	",
      answers: [
        "	Parts of the steering system are worn out	",
        "	Use of different brands of tyres	",
        "	Different air pressures in front tyres	",
        "	Different tread patterns in front tyres	",
      ],
      questionId: 29,
      correct: "	Parts of the steering system are worn out	",
      image: "",
    },
    {
      question: "	What's the function of a water pump in a vehicle?	",
      answers: [
        "	To facilitate the circulation of engine oil	",
        "	To facilitate water circulation in the engine	",
        "	To cool the water used in the engine	",
        "	To cool the radiator	",
      ],
      questionId: 30,
      correct: "	To facilitate water circulation in the engine	",
      image: "",
    },
    {
      question:
        "	What should you do when  your brake light comes on while driving?	",
      answers: [
        "	Take the vehicle to the nearest garage	",
        "	Drive to your destination carefully	",
        "	Stop the vehicle immediately and check whether you have brake fluid	",
        "	Park the vehicle until a technician is called to inspect it	",
      ],
      questionId: 31,
      correct:
        "	Stop the vehicle immediately and check whether you have brake fluid	",
      image: "",
    },
    {
      question: "	When tyres have low air pressure	",
      answers: [
        "	Only one side of the tyre will be worn out	",
        "	Both sides of the tyre will wear out, but not the middle	",
        "	Middle of the tyre will wear out, but not sides	",
        "	Air pressure has no effect on the wearing off of tyres	",
      ],
      questionId: 32,
      correct: "	Both sides of the tyre will wear out, but not the middle	",
      image: "",
    },
    {
      question:
        "	What increases the free movement of steering system/free play of the steering system?	",
      answers: [
        "	Front tyres are worn out	",
        "	Looseness in the steering gear	",
        "	Air pressure of the front tyres is too high	",
        "	Air pressure of the front tyres is too low	",
      ],
      questionId: 33,
      correct: "	Looseness in the steering gear	",
      image: "",
    },
    {
      question:
        "	If the engine of the vehicle you drive is overheated and water is boiled, when filling it with water	",
      answers: [
        "	There is no need be concerned about the engine	",
        "	The engine must be stopped and refilled with water	",
        "	Water must be filled while engine is running	",
        "	Both of the above are correct	",
      ],
      questionId: 34,
      correct: "	Water must be filled while engine is running	",
      image: "",
    },
    {
      question:
        "	What's the immediate action to take if you feel the smell of burning wire in the vehicle due to an electric fault?	",
      answers: [
        "	Turn off all the lights in the vehicle	",
        "	Turn off the main switch	",
        "	Remove the fuse	",
        "	Remove the battery wire	",
      ],
      questionId: 35,
      correct: "	Remove the battery wire	",
      image: "",
    },
    {
      question: "	To reduce the risk of slipping	",
      answers: [
        "	It's not possible to reduce the risk of slipping	",
        "	speed should be controlled by applying brakes suddenly	",
        "	The steering wheel should be turned to the direction opposite to slipping, without applying brake	",
        "	With the use of gears, the steering wheel should be controlled in the direction of slipping	",
      ],
      questionId: 36,
      correct:
        "	With the use of gears, the steering wheel should be controlled in the direction of slipping	",
      image: "",
    },
    {
      question:
        "	If the brake pedal sinks all the way to the floor in a vehicle that uses brake fluid, this indicates	",
      answers: [
        "	There is a fault in the brake pedal	",
        "	A brake bind	",
        "	Brake is working properly	",
        "	A leak in brake fluid	",
      ],
      questionId: 37,
      correct: "	A leak in brake fluid	",
      image: "",
    },
    {
      question:
        "	 If a vehicle pulls to the left, when applying brakes, this means 	",
      answers: [
        "	Wheels have different air pressures	",
        "	There is a brake fluid leakage in the front left wheel	",
        "	There is a brake fluid leakage in the front right wheel	",
        "	The back wheels have no brakes	",
      ],
      questionId: 38,
      correct: "	There is a brake fluid leakage in the front right wheel	",
      image: "",
    },
    {
      question:
        "	If you experience difficulty in changing gears while driving, the most likely cause is 	",
      answers: [
        "	There is a fault in the gear lever	",
        "	The clutch is not working properly	",
        "	The engine speed is not up to the level	",
        "	There is a fault in the gear box	",
      ],
      questionId: 39,
      correct: "	The clutch is not working properly	",
      image: "",
    },
    {
      question: "	When the air pressure in the tyre is too high	",
      answers: [
        "	The tyre will wear out more in some places	",
        "	Tyre will wear out quickly	",
        "	Middle of the tyre will wear out quickly	",
        "	Two sides of the tyre will wear out quickly	",
      ],
      questionId: 40,
      correct: "	Middle of the tyre will wear out quickly	",
      image: "",
    },
  ],
};
