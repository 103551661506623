import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import SLDLsinhala from "../../assets/images/රියදුරු බලපත්‍ර විභාග පිළිතුරු.png";
import SLDLTamil from "../../assets/images/Sri Lanka Driving Exam - தமிழ்.png";
import SLDLEnglish from "../../assets/images/Sri Lankan Driving Exam Prep.png";
import SLDLIOS from "../../assets/images/Mechsit-Sri lanka drivers exam.png";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/HomeDummyData";
import PaperShiftButtons from "../PaperFourShiftButtons/PaperShiftButtons";
import "./HomePageMain.css";
import UserDetailsModal from "../../views/Forms/UserDetailForms/UserDetailsModal";


function HomePageMain() {
  
  const [language, setLanguage] = useState(sinhalaData[0]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem("modalShown", "true"); 
  };

  useEffect(() => {
    const selectedLanguageValue = localStorage.getItem("languageValue");
    //const modalShown = localStorage.getItem("modalShown");

    switch (selectedLanguageValue) {
      case "english":
        setLanguage(englishData[0]);
        //setSelectedAnswers(Array(englishData.length).fill(""));
        break;
      case "tamil":
        setLanguage(tamilData[0]);
        //setSelectedAnswers(Array(tamilData.length).fill(""));
        break;
      default:
        setLanguage(sinhalaData[0]);
        //setSelectedAnswers(Array(sinhalaData.length).fill(""));
        break;
    }

    // setShowModal(true);
    // if (!modalShown) {
    //   setShowModal(true);
    //   localStorage.setItem("modalShown", "true");
    // }
  }, [language]);


  // const handleButtonClick = (props) => {
  //   window.location.href = props.link;
  // };

  return (
    <div>
      {/* <div className="home-page-hero">
        <div className="text-center">
          <h1 className="home-main-title-h1">{language.title}</h1>
          <h1 className="home-main-title-h1">{language.subTitle}</h1>
          <p className="home-main-title-p">{language.description}</p>
          <button className="btn btn-warning text-light px-5 py-2 w-auto w-sm-100">
            Join Now
          </button>
        </div>
      </div> */}

      <div className="home-page-hero">
        <div className="text-center text-container">
          <Container fluid className="d-flex justify-content-center py-5">
            <div className="section-vertical-space">
              <div class="section-container ">
                <Row className="d-flex align-items-start justify-content-center py-5">
                  <Col sm={6} className="d-flex flex-column align-items-start">
                    <div className="d-flex justify-content-start p-2 ">
                      {/* {language.title} */}
                      <Row className="d-flex flex-column text-start w-100">
                        <h5 className="startnowtxt-head fs-1">
                          Mock Tests for Driving licence exam Success!{" "}
                        </h5>
                        <h5 className="startnowtxt-head fs-4">
                          මෝටර් රථ දාවන බලපත්‍රය පරීක්ෂණය සඳහා ආදර්ශ පරීක්ෂණ
                        </h5>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={6} className="d-flex flex-column align-items-start">
                    <div className="d-flex justify-content-start p-2">
                      <Row>
                        <h6 className="d-flex startnowtxt">
                          {/* {language.subTitle} */}"Practice with mock tests
                          and ace your driving exam effortlessly! Stay confident
                          and ready with realistic questions and instant
                          feedback. Your journey to driving success starts
                          here!"
                        </h6>
                        <h6 className="d-flex startnowtxt">
                          ආදර්ශ පරීක්ෂණ සමඟ පුහුණු වී ඔබේ රියදුරු විභාගය
                          පහසුවෙන් සමත් වන්න! යථාර්ථවාදී ප්‍රශ්න සහ ක්ෂණික
                          ප්‍රතිපෝෂණ සමඟ විශ්වාසයෙන් හා සූදානම්ව, රිය පැදවීමේ
                          සාර්ථකත්වය සඳහා ඔබේ ගමන මෙතැනින් ආරම්භ කරන්න!
                        </h6>
                      </Row>
                    </div>
                  </Col>
                  <div className="hide-on-small">
                    <PaperShiftButtons
                      First={language.paperNo1}
                      Second={language.paperNo2}
                      Third={language.paperNo3}
                      Fourth={language.paperNo4}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="hide-on-large">
        <PaperShiftButtons
          First={language.paperNo1}
          Second={language.paperNo2}
          Third={language.paperNo3}
          Fourth={language.paperNo4}
        />
      </div>

      {/* <Container fluid className="py-5 articles-section">
        <div className="section-vertical-space">
          <h2 className="section-title text-center mb-5 justify-content-start">
            Top Articles to Boost Your Learning
          </h2>
          <Row className="d-flex justify-content-center">
            {language.articlesCard.map((article, index) => (
              <Col
                key={index}
                lg={3}
                md={6}
                sm={12}
                className="mb-4 d-flex align-items-stretch"
              >
                <Card className="shadow-sm article-card">
                  <Card.Img
                    variant="top"
                    src={article.image}
                    className="article-image"
                  />
                  <Card.Body>
                    <Card.Title>{article.title}</Card.Title>
                    <Card.Text>{article.description}</Card.Text>
                    <Button className="btn-outline-dark justify-content-start .btn-articles">
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container fluid className="get-driving-school-section">
        
        <div className="row align-items-center">
         
          <div className="col-12 col-md-6 p-0">
            <img
              src={getDrivingSchool}
              alt="Driving School Promo"
              className="img-fluid get-driving-school-image"
            />
          </div>

          <div className="col-md-6 text-white d-flex flex-column justify-content-end py-3 px-5 px-md-5">
            <h1 className="promo-title text-center text-md-start">
              Get Your Driving School Listed Here – It’s Free!
            </h1>
            <p className="promo-description text-justify text-center text-md-start">
              Join our platform and get your driving school listed for free!
              It's a simple way to connect with students looking for reliable,
              certified driving instruction. Reach a larger audience, and make
              it easier for new learners to find your school. Register today and
              grow your business!
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <button className="btn btn-warning promo-button">
                REGISTER NOW
              </button>
            </div>
          </div>
        </div>
       
      </Container>

      <Container className="driving-form-section">
        <div class="section-container p-4">
          <div className="section-vertical-space">
            <Row className="mb-2">
              <Col>
                <h2 className="form-title">Ready to Learn Driving?</h2>
                <p className="form-description">
                  Driver's education, driver education, driving education,
                  driver's training, driver's ed, driving tuition or driving
                  lessons is a formal class or program that prepares a new
                  driver to obtain a learner's permit or driver's license.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="form-subtitle">
                  Enter Your Information to Get Started
                </h3>
              </Col>
            </Row>
            <UserDetailsForm />
          </div>
        </div>
      </Container> */}

      <Container fluid className="p-5 articles-section section-vertical-space">
        <div className="section-container section-vertical-space">
          <Row className="d-flex ps-lg-5 ps-md-3 ps-sm-1 justify-content-center">
            <Col
              lg={3}
              md={6}
              sm={12}
              className="mb-4 text-center text-lg-start"
            >
              <p className="mb-3 fs-1 text-lg-start text-md-center text-sm-center">
                Download
              </p>
              <p className="mb-4 fs-4 text-lg-start text-md-center text-sm-center">
                The Other Apps
              </p>
              <p className="text-lg-start text-md-center text-sm-center">
                Get the most out of our services! Download our other app for
                more features and benefits.
              </p>
            </Col>

            <Col lg={9} md={12} sm={12}>
              <Row className="d-flex justify-content-center">
                {/* Sinhala App */}
                <Col lg={3} md={6} sm={12} className="text-center mb-4">
                  <a
                    href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl&hl=en"
                    className="apps-click"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={SLDLsinhala}
                      className="mb-2 our-app-image"
                      alt="driving license exam paper sinhala"
                    />
                    <p className="mt-2">SLDL Sinhala App</p>
                  </a>
                </Col>
                {/* Tamil App */}
                <Col lg={3} md={6} sm={12} className="text-center mb-4">
                  <a
                    href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.tm&hl=ta&pli=1"
                    className="apps-click"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={SLDLTamil}
                      className="mb-2 our-app-image"
                      alt="MechSIT SLDL Tamil App"
                    />
                    <p className="mt-2">SLDL Tamil App</p>
                  </a>
                </Col>
                {/* English App */}
                <Col lg={3} md={6} sm={12} className="text-center mb-4">
                  <a
                    href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.en&hl=en"
                    className="apps-click"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={SLDLEnglish}
                      className="mb-2 our-app-image"
                      alt="MechSIT SLDL English App"
                    />
                    <p className="mt-2">SLDL English App</p>
                  </a>
                </Col>
                <Col lg={3} md={6} sm={12} className="text-center mb-4">
                  <a
                    href="https://apps.apple.com/eg/app/mechsit-sri-lanka-drivers-exam/id1599318476"
                    className="apps-click"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={SLDLIOS}
                      className="mb-2 our-app-image"
                      alt="MechSIT SLDL IOS App"
                    />
                    <p className="mt-2">SLDL for IOS</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      <footer className="footer-section">
        <Container fluid className="get-driving-school-section px-5 py-4">
          <Row className="justify-content-center text-center text-md-start">
            {/* Company Info */}
            <Col lg={4} md={6} sm={12} className="mb-4">
              <h5 className="footer-title">MECHSIT (PVT) LTD</h5>
              <p className="footer-text">
                Where innovation meets excellence. Our team is committed to
                delivering top-notch solutions.
              </p>
            </Col>

            {/* Contact Info */}
            <Col lg={4} md={6} sm={12} className="mb-4">
              <h4>Contact Us</h4>
              <p>
                <strong>Phone</strong>
                <br />
                +94 71 773 4346
                <br />
                +94 76 574 7783
              </p>
              <p>
                <strong>Email</strong>
                <br />
                <a href="mailto:info@mechsit.com" style={{ color: "#FFFFFF" }}>
                  info@mechsit.com
                </a>
              </p>
              <p>
                <strong>Address</strong>
                <br />
                No. 100/1, Dumbara Uyana,
                <br /> Balagolla, Kengalla
              </p>
            </Col>

            {/* Subscribe Newsletter */}
            <Col lg={4} md={6} sm={12} className="px-2">
              <h4 className="footer-title">Subscribe Newsletter</h4>
              <p className="footer-text">
                Subscribe to our newsletter to get updates about our services
                and offers
              </p>
              <Form>
                <Form.Group className="d-flex">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      border: "none",
                      height: "50px",
                    }}
                  />
                  <Button
                    className="subscribe-button"
                    variant="primary"
                    style={{
                      width: "100px",
                      height: "50px",
                      borderRadius: "0px 20px 20px 0px",
                      backgroundColor: "#00AEEF",
                      border: "none",
                    }}
                  >
                    &#x27A4;
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <div className="footer-bottom text-center mt-3">
            <p>
              <a
                href="https://mecsit.com/"
                className="text-white text-decoration-none"
                alt="MECHSIT"
              >
                © 2024 MECHSIT (PVT) LTD
              </a>
              . All Rights Reserved.
            </p>
          </div>
        </Container>
      </footer>
      <div>
        <UserDetailsModal showModal={showModal} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default HomePageMain;