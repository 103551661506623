import TopNav from "../TopNav/TopNav";
import React from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Contact.css";

const Contact = () => {
  const form = useRef();
  const [messageStatus, setMessageStatus] = useState("");
  const [formError, setFormError] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const message = form.current.message.value.trim();

    if (!name || !email || !message) {
      setFormError("Please fill out all fields.");
      setMessageStatus("");
      return;
    }

    setFormError("");

    emailjs
      .sendForm("service_pfbv76k", "template_yv2lvnt", form.current, {
        publicKey: "jqc6VLfJexTyBMv-C",
      })
      .then(
        () => {
          setMessageStatus("SUCCESS");
          form.current.reset();
        },
        (error) => {
          setMessageStatus(`FAILED: ${error.text}`);
        }
      );
  };
  return (
    <div>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>
      {/* <div className="contact-img-hero"> */}
      <div className="d-flex top-0 start-0 w-100">
        <Container
          fluid
          className="d-flex container-fluid justify-content-center"
        >
          <Row
            className="d-flex  justify-content-center pt-5 "
            style={{ marginTop: 10 }}
          >
            <Row className="d-flex  justify-content-center mt-5 pb-4">
              {/* <h3 className="d-flex justify-content-center startnowtxt">
                    Contact Us
                  </h3> */}
            </Row>
            <Col
              sm={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <div className="bg-color-contact-data text-light p-5 rounded bg-opacity-25 border border-light d-flex flex-column align-items-start justify-content-center w-100">
                <p className="d-flex w-100 mb-5 sm-text">
                  Welcome to our Contact Us page! We're here to help with any
                  questions or feedback. Let us know how we can assist you!
                </p>

                {/* Phone Section */}
                <div className="d-flex w-100 mb-3">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faPhone} size="2x" />
                  </div>
                  <div className="col-8 d-flex flex-column align-items-start justify-content-center">
                    <h6>CALL US</h6>
                    <p className="p-1 sm-text text-start">
                      +94 71 773 4346 / +94 76 574 7783
                    </p>
                    {/* <div className="p-1">+94 76 574 7783</div> */}
                  </div>
                </div>

                {/* Email Section */}
                <div className="d-flex w-100 mb-3">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  </div>
                  <div className="col-8 d-flex flex-column align-items-start justify-content-center">
                    <h6>EMAIL</h6>
                    <div className="p-1 sm-text">
                      <a
                        href="mailto:lankalearnerslk@gmail.com"
                        style={{ color: "#FFFFFF" }}
                        className=" text-decoration-none"
                      >
                        lankalearnerslk@gmail.com
                      </a>
                    </div>
                  </div>
                </div>

                {/* Address Section */}
                <div className="d-flex w-100">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                  </div>
                  <div className="col-8 d-flex flex-column align-items-start ">
                    <h6>ADDRESS</h6>
                    {/* <p className="sm-text">MECHSIT (PVT) LTD,</p> */}
                    <p className="d-flex flex-column text-start sm-text">
                      MECHSIT (PVT) LTD, No. 100/1, Dumbara Uyana,
                      Balagolla,Kengalla
                    </p>
                  </div>
                </div>
                <div
                  className="p-3 d-flex align-items-start"
                  style={{ zIndex: "100", left: "20px" }}
                >
                  <a
                    href="https://web.facebook.com/mechsit/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                    alt="Follow MechSIT on Facebook"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="2x"
                      color="#4267B2"
                    />
                  </a>
                  {/* <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      color="#E1306C"
                    />
                  </a> */}
                  <a
                    href="https://wa.me/94765747783"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                    alt="Chat with MechSIT on WhatsApp"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      size="2x"
                      color="#25D366"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/mechsit/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                    alt="Follow MechSIT on LinkedIn"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="2x"
                      color="#0077B5"
                    />
                  </a>
                  {/* <a
                    href="https://www.tiktok.com/@user7491468203836?is_from_webapp=1&sender_device=pc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                  >
                    <FontAwesomeIcon
                      icon={faTiktok}
                      size="2x"
                      color="#000000"
                    />
                  </a>

                  <a
                    href="https://www.youtube.com/@mechsitapps"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="2x"
                      color="#FF0000"
                    />
                  </a> */}
                  {/* <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="m-2"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="2x"
                      color="#1DA1F2"
                    />
                  </a> */}
                </div>
              </div>
            </Col>
            <Col sm={6} className="d-flex p-5 flex-column justify-content-end">
              <div className="row mb-4">
                <h3 className="d-flex col justify-content-end fs-1">
                  Contact{" "}
                </h3>
                <h3 className="d-flex col justify-content-start fs-1 text-warning fw-bold">
                  Us
                </h3>
              </div>

              <div className="container overflow-hidden">
                <form
                  ref={form}
                  onSubmit={sendEmail}

                  //bg-success p-2 text-dark bg-opacity-50
                >
                  <div className="firstRow">
                    <div className="mb-3">
                      <input
                        name="user_name"
                        type="text"
                        id="disabledTextInput"
                        className="form-control text-start"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        name="user_email"
                        type="text"
                        id="disabledTextInput"
                        className="form-control text-start"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <textarea
                      type="text"
                      id="disabledTextInput"
                      className="form-control text-start"
                      cols="30"
                      name="message"
                      rows="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="mb-3 form-check d-flex align-items-start">
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="isNewsletter"
                      id="inlineRadio1"
                      value="yes"
                      // onChange={handleInputChange}
                    />
                    <label
                      className="form-label text-start"
                      htmlFor="inlineRadio1"
                    >
                      I would like to receive the newsletter.
                    </label>
                  </div>

                  {formError && (
                    <div className="text-danger mb-3">{formError}</div>
                  )}
                  {messageStatus === "SUCCESS" && (
                    <div className="text-success mb-3">
                      Message sent successfully!
                    </div>
                  )}
                  {messageStatus.startsWith("FAILED") && (
                    <div className="text-danger mb-3">
                      Message failed to send!
                      {/* {messageStatus} */}
                    </div>
                  )}
                  <div className="mb-3  text-start">
                    <a className="submit" href=" ">
                      <button
                        type="submit"
                        value="Send"
                        className="btn btn-warning col-4 text-light justify-content-start"
                      >
                        Send Message
                      </button>
                    </a>
                  </div>
                  {/* <div className="mb-3"></div> */}
                </form>
              </div>
            </Col>
          </Row>
          {/* <div
            className="fixed-bottom right-100 p-3"
            style={{ zIndex: "100", left: "initial" }}
          >
            <a
              href="https://wa.me/94778274522?text=I would like to know more about your products."
              title="Chat with us on WhatsApp"
            >
              <Image src={WhatsAppIMG} width={180} />
            </a>
          </div> */}
        </Container>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Contact;
