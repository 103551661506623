import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DrivingSchoolCard.css"; 
import UserDetailsModal from "../../views/Forms/UserDetailForms/UserDetailsModal";

function DrivingSchoolCard({ name, city, description, image }) {

const navigate = useNavigate();
//const [isFormFilled, setIsFormFilled] = useState(false);
const [showModal, setShowModal] = useState(false);

// const checkFormFilled = () => {
//   if (localStorage.getItem("isFormFilled") === "true") {
//     setIsFormFilled(true);
//     return true;
//   } else {
//     setIsFormFilled(false);
//     return false;
//   }
// };

const handleJoinNowClick = (e) => {
  // if (checkFormFilled()) {
    e.stopPropagation();
    navigate("/joinPage");
  // } else {
    
  // }
};

const handleCloseModal = () => {
  setShowModal(false);
};


  return (
    <div className="card mx-auto driving-card">
      <div className="row card-body">
        <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
          <img
            src={image}
            alt={`${name} illustration`}
            className="img-thumbnail img-fluid driving-school-card-image"
          />
        </div>

        <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
          <h5 className="card-title text-start">{name}</h5>
          <h6 className="card-subtitle mb-2 text-muted text-start">{city}</h6>
          <p className="card-text text-start">{description}</p>
        </div>

        <div className="col-12 col-md-2 d-flex justify-content-center align-items-end">
          <button
            className="btn btn-warning text-light w-100"
            onClick={handleJoinNowClick}
          >
            Join Now
          </button>
        </div>
      </div>
      {/* Add User Details Modal */}
      <UserDetailsModal showModal={showModal} handleClose={handleCloseModal} />
    </div>
  );
}

export default DrivingSchoolCard;
