import AllRoadSigns from "../../src/assets/cardImages/AllRoadSigns.png";
import TrafficRulesandVehicleFines from "../../src/assets/cardImages/TrafficRulesandVehicleFines.png";
import Vehiclehandbook from "../../src/assets/cardImages/Vehiclehandbook.png";

export const englishData = [
  {
    title: "Ready to Drive?",
    subTitle: "Begin Your Journey with Us!",
    description:
      "Prepare for your driving test with expert resources and mock exams designed for success.",
    modelTitle: "Change in Question Paper Format",
    allqueButton: "I want to Answer all questions once",
    oneByoneButton: "I want to Answer questions one by one",
    cardOne: "Traffic Rules and Vehicle Fines",
    cardTwo: "Vehicle hand book",
    cardThree: "All Road Signs",
    paperNo1: "Paper 01",
    paperNo2: "Paper 02",
    paperNo3: "Paper 03",
    paperNo4: "Paper 04",
    articlesCard: [
      {
        title: "Traffic Rules and Vehicle Fines",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "All Road Signs",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "Vehicle hand book",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];

export const sinhalaData = [
  {
    title: "මෝටර් රථ දාවන බලපත්‍රය ලබාගැනීමට ඔබ සූදානම්ද ?",
    subTitle: "එන්න, අප හා එක්වන්න !",
    description:
      "විභාගයට සාර්ථකව මුහුණදීමට විශේෂඥ පුහුණුව, උපදෙස්  සහ පෙර පුහුණු ප්‍රශ්න පත්‍ර.",
    modelTitle: "ප්‍රශ්න පත්‍ර ආකාරය වෙනස් කිරීම",
    allqueButton: "සියලුම ප්‍රශ්න වලට එකවර පිළිතුරු දීමට",
    oneByoneButton: "ප්‍රශ්න වලට එකින් එක පිළිතුරු දීමට",
    cardOne: "රථවාහන නීති සහ වාහන දඩ",
    cardTwo: "රියදුරන්ගේ අත්පොත",
    cardThree: "සියලුම මාර්ග සංඥා",
    paperNo1: "ප්‍රශ්න පත්‍ර අංක 01",
    paperNo2: "ප්‍රශ්න පත්‍ර අංක 02",
    paperNo3: "ප්‍රශ්න පත්‍ර අංක 03",
    paperNo4: "ප්‍රශ්න පත්‍ර අංක 04",
    articlesCard: [
      {
        title: "රථවාහන නීති සහ වාහන දඩ",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "සියලුම මාර්ග සංඥා",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "රියදුරන්ගේ අත්පොත",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];

export const tamilData = [
  {
    title: "ஓட்டுவதற்குத் தயாரா?",
    subTitle: "எங்களுடன் உங்கள் பயணத்தைத் தொடங்குங்கள்!",
    description:
      "நிபுணத்துவ ஆதாரங்கள் மற்றும் வெற்றிக்காக வடிவமைக்கப்பட்ட போலித் தேர்வுகளுடன் உங்கள் ஓட்டுநர் சோதனைக்குத் தயாராகுங்கள்.",
    modelTitle: "வினாத்தாள் வடிவத்தில் மாற்றம்",
    allqueButton: "அனைத்து கேள்விகளுக்கும் ஒரே நேரத்தில் பதிலளிக்க",
    oneByoneButton: "கேள்விகளுக்கு ஒவ்வொன்றாகப் பதில் சொல்ல வேண்டும்",
    cardOne: "போக்குவரத்து சட்டங்கள் மற்றும் வாகன அபராதம்",
    cardTwo: "ஓட்டுநர் கையேடு",
    cardThree: "அனைத்து சாலை அடையாளங்களும்",
    paperNo1: "வினாத்தாள் எண். 01",
    paperNo2: "வினாத்தாள் எண். 02",
    paperNo3: "வினாத்தாள் எண். 03",
    paperNo4: "வினாத்தாள் எண். 04",
    articlesCard: [
      {
        title: "போக்குவரத்து சட்டங்கள் மற்றும் வாகன அபராதம்",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "அனைத்து சாலை அடையாளங்களும்",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "ஓட்டுநர் கையேடு",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];
