import React from 'react';
import "./Home.css";
import TopNav from '../TopNav/TopNav';
import HomePageMain from '../../Components/HomePageMain/HomePageMain';
import { Helmet } from 'react-helmet';

function Home() {

    //const userId = useSelector((state) => state?.paper?.isPaper);

    return (
      <>
        <Helmet>
          {/* Primary Meta Tags */}
          <title>
            Lanka Learners | Your Ultimate Guide for Driving License Exam
            Preparation
          </title>
          <meta
            name="description"
            content="Lanka Learners provides comprehensive resources, guides, and quizzes to help you successfully prepare for your driving license exams in Sri Lanka. Start learning today!"
          />
          <meta
            name="keywords"
            content="Sri Lanka driving license, driving license preparation, driving exam guide, driving quizzes, Lanka Learners"
          />
          <meta name="author" content="Lanka Learners" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Lanka Learners | Driving License Exam Preparation"
          />
          <meta
            property="og:description"
            content="Prepare for your driving license exams in Sri Lanka with interactive quizzes, handbooks, and more. Join Lanka Learners today!"
          />
          <meta property="og:url" content="https://lankalearners.lk/" />
          <meta
            property="og:image"
            content="https://lankalearners.lk/images/sldl.png"
          />{" "}
          {/* Replace with actual image URL */}
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Lanka Learners | Driving License Exam Preparation"
          />
          <meta
            name="twitter:description"
            content="Learn everything you need to pass your driving license exams in Sri Lanka with Lanka Learners."
          />
          <meta
            name="twitter:image"
            content="https://lankalearners.lk/images/sldl.png"
          />{" "}
          {/* Replace with actual image URL */}
          {/* Additional Meta Tags */}
          <link rel="canonical" href="https://lankalearners.lk/" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Lanka Learners",
                        "url": "https://lankalearners.lk/",
                        "logo": "https://lankalearners.lk/images/logo.png",
                        "description": "Lanka Learners is Sri Lanka’s top platform for driving exam preparation.",
                        "sameAs": [
                            "https://www.facebook.com/mechsit",
                            "https://twitter.com/LankaLearners",
                            "https://www.instagram.com/LankaLearners",
                            "https://www.linkedin.com/company/mechsit"
                        ]
                    }
                    `}
          </script>
        </Helmet>
        <div className="position-fixed w-100 show-topnav-top">
          <TopNav />
        </div>

        <HomePageMain />
      </>
    );
}

export default Home;