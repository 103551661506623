import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNav from "../TopNav/TopNav";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/papersPageData";
import "./PapersPage.css";
import ChangePaperModal from "../../Components/PaperFourShiftButtons/PaperSelectModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaperIcon from "../../assets/PaperSelect/papersPage.webp";

function PapersPage() {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state) => state.language.language);
  const [language, setLanguage] = useState(sinhalaData);
  const [isModelOpen, setIsModelOpen] = useState(false);
  // const [selectedPaper, setSelectedPaper] = useState({
  //   label: "First Paper",
  //   value: "first",
  // });

  useEffect(() => {
    switch (selectedLanguage.value) {
      case "english":
        setLanguage(englishData);
        break;
      case "tamil":
        setLanguage(tamilData);
        break;
      default:
        setLanguage(sinhalaData);
        break;
    }
  }, [selectedLanguage]);

  // useEffect(() => {
  //   const storedPaperLabel =
  //     localStorage.getItem("paperLabel") || "First Paper";
  //   const storedPaperValue = localStorage.getItem("paperValue") || "first";
  //   //setSelectedPaper({ label: storedPaperLabel, value: storedPaperValue });
  // }, []);

  const handleOpenPaper = (route) => {
    setTimeout(() => {
      navigate(route); 
    });
  };

  const handleOpenChangePaperModal = (label, value) => {
    // setIsModelOpen(true); 
    localStorage.setItem("paperLabel", label);
    localStorage.setItem("paperValue", value);
    handleOpenPaper("/paper");
    //setSelectedPaper({ label, value });
  };

  const handleCloseChangePaperModal = () => {
    setIsModelOpen(false); 
  };

  return (
    <div>
      <div className="position-fixed w-100 show-topnav-under-papers">
        <TopNav />
      </div>

      <div className="position-absolute top-0 start-0 w-100 mt-5 pt-2 papers-content">
        <Row className="d-flex  justify-content-center mt-5 pb-4">
          <h3 className="fs-1 d-flex justify-content-center fw-bold pb-2">
            Papers <span className="text-warning fw-bold"></span>
          </h3>
        </Row>
        <Container fluid className="d-flex justify-content-center">
          <Row className="d-flex  justify-content-center pb-4">
            {/* <h6 className="d-flex px-5 papers-description-text">
              {language.description}
            </h6> */}

            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 papers-card"
                onClick={() =>
                  handleOpenChangePaperModal("First Paper", "first")
                }
              >
                <div className="jumbotron rounded">
                  <img
                    src={PaperIcon}
                    className="img-fluid p-3"
                    alt="license exam paper"
                  />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo1}</h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 papers-card"
                onClick={() =>
                  handleOpenChangePaperModal("Second Paper", "second")
                }
              >
                <div className="jumbotron rounded">
                  <img
                    src={PaperIcon}
                    className="img-fluid p-3"
                    alt="driving exam question and answer"
                  />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo2}</h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 papers-card"
                onClick={() =>
                  handleOpenChangePaperModal("Third Paper", "third")
                }
              >
                <div className="jumbotron rounded">
                  <img
                    src={PaperIcon}
                    className="img-fluid p-3"
                    alt="learner license test questions and answers"
                  />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3 fs-5 fs-sm-6 fs-lg-4">
                      {language.paperNo3}
                    </h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 papers-card"
                onClick={() =>
                  handleOpenChangePaperModal("Fourth Paper", "fourth")
                }
              >
                <div className="jumbotron rounded">
                  <img
                    src={PaperIcon}
                    className="img-fluid p-3"
                    alt="question and answers for learners licence test"
                  />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo4}</h5>
                  </div>
                </div>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      {isModelOpen && (
        <ChangePaperModal
          showModal={isModelOpen}
          handleClose={handleCloseChangePaperModal}
        />
      )}
    </div>
  );
}

export default PapersPage;
