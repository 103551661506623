import { apiSlice } from "../apiSlice"

export const drivingSchoolService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSingleDrivingSchool: builder.mutation({
      query: ({ drivingSchoolId }) => {
        let url = `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${encodeURIComponent(
          drivingSchoolId
        )}`;

        return {
          url,
          method: "GET",
          responseHandler: (response) => response.json(),
        };
      },
    }),

    getAllDrivingSchools: builder.mutation({
      query: () => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools`,
        method: "GET",
        responseHandler: (response) => response.json(),
      }),
    }),

    addDrivingSchool: builder.mutation({
      query: (data) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),

    updateDrivingSchool: builder.mutation({
      query: ({ drivingSchoolId, data }) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${drivingSchoolId}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteDrivingSchool: builder.mutation({
      query: ({ drivingSchoolId, data }) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${drivingSchoolId}`,
        method: "DELETE",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSingleDrivingSchoolMutation,
  useGetAllDrivingSchoolsMutation,
  useAddDrivingSchoolMutation,
  useUpdateDrivingSchoolMutation,
  useDeleteDrivingSchoolMutation,
} = drivingSchoolService;