import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Members.css";
import {
  useGetMembersByStatusQuery,
  useGetNonMembersUsersQuery,
  useUpdateMembersByStatusMutation,
} from "../../../redux/service/membersService";

function Members() {
  const [activeKey, setActiveKey] = useState("pendingRequests");
  const [searchQuery, setSearchQuery] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [acceptedMembers, setAcceptedMembers] = useState([]);

  // const [memberId, setMemberId] = useState("");
  // const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);

  const drivingSchoolId = localStorage.getItem("userId");

  // const [GetAllUsers] = useGetAllUsersMutation();
  // const [trigger, { data, error, isLoading }] =
  //   useGetMembersByStatusQuery();
  // const { data, error, isLoading } = useGetMembersByStatusQuery({
  //   drivingSchoolId,
  //   status: "pending",
  // });
    const {
      data: pendingData,
      error: pendingError,
      isLoading: pendingLoading,
    } = useGetMembersByStatusQuery({
      drivingSchoolId,
      status: "pending",
    });
      const {
        data: acceptedData,
        error: acceptedError,
        isLoading: acceptedLoading,
      } = useGetMembersByStatusQuery({
        drivingSchoolId,
        status: "accepted",
      });

  const {
    data: nonMembersData,
    error: nonMembersError,
    isLoading: nonMembersLoading,
  } = useGetNonMembersUsersQuery({
    drivingSchoolId,
  });


  const [updateMemberStatus] = useUpdateMembersByStatusMutation();


  // const handleGetAllUsers = async () => {
  //   console.log("Fetching all users...");
  //   try {
  //     const response = await GetAllUsers();
  //     if (response?.data) {
  //       console.log("Fetched users successfully:", response.data);
  //       setUsersList(response.data);
  //     } else {
  //       console.error("Failed to fetch users:", response.error);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while fetching users:", error);
  //   }
  // };

  const handleStatusUpdate = async (status, memberId) => {
    try {
      const data = {
        memberId,
        drivingSchoolId,
        status,
      };

      const response = await updateMemberStatus(data).unwrap();
      console.log("Response:", response); // Handle response (e.g., show a toast)
    } catch (error) {
      console.error("Failed to update status:", error); // Handle error (e.g., show an error message)
    }
  };

  //  const handleGetPendingMembers = async () => {
  //    console.log("Fetching pending members...");
  //    try {
  //      const response = await useGetMembersByStatusQuery({
  //        drivingSchoolId,
  //        status: "pending",
  //      });
  //      if (response?.data) {
  //        console.log("Fetched users successfully:", response.data);
  //        setPendingMembers(response.data);
  //      } else {
  //        console.error("Failed to fetch users:", response.error);
  //      }
  //    } catch (error) {
  //      console.error("Error occurred while fetching users:", error);
  //    }
  //  };

  // useEffect(() => {
  //   handleGetAllUsers();
  // }, []);

  //  useEffect(() => {
  //    if (drivingSchoolId) {
  //      const response = trigger({ drivingSchoolId, status: "pending" });
  //      console.log(response.data);
  //    }
  //  }, [drivingSchoolId, trigger]);

  // useEffect(() => {
  //   if (data?.members) {
  //     console.log("Fetched users successfully:", data);
  //     setPendingMembers(data.members); 
  //   } else if (error) {
  //     console.error("Failed to fetch users:", error);
  //   }
  // }, [data, error]);

    useEffect(() => {
      if (pendingData?.members) {
        setPendingMembers(pendingData.members);
      } else if (pendingError) {
        console.error("Error fetching pending members:", pendingError);
      }
    }, [pendingData, pendingError]);

    useEffect(() => {
      if (acceptedData?.members) {
        setAcceptedMembers(acceptedData.members);
      } else if (acceptedError) {
        console.error("Error fetching accepted members:", acceptedError);
      }
    }, [acceptedData, acceptedError]);

    useEffect(() => {
      if (nonMembersData?.data) {
        setUsersList(nonMembersData?.data);
      } else if (nonMembersError) {
        console.error("Error fetching non-members:", nonMembersError);
      }
    }, [nonMembersData, nonMembersError]);

  // if (isLoading) return <div>Loading...</div>;

  console.log(usersList, "UsersList ===================>");
  console.log("Fetched nonMembers successfully:", nonMembersData);

  const filteredMembers = usersList.filter(
    (member) =>
      member.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.city?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  console.log(filteredMembers, "Filtered Members ===================>");

  // useEffect(() => {
  //   const fetchMembers = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await fetch(
  //         "https://api-fyfkcojn3q-uc.a.run.app/api/user/getAllUsers"
  //       );
  //       const data = await response.json();
  //       setMembers(data);
  //     } catch (error) {
  //       setError("Failed to fetch members");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchMembers();
  // }, []);

  //   const filteredMembers = members.filter(
  //     (member) =>
  //       member.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       member.city.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  // const renderPendingTab = () => (
  //   <div className="request-list">
  //     {pendingMembers.map((request, index) => (
  //       <RequestCard
  //         key={index}
  //         name={`${request.firstName} ${request.lastName}`}
  //         city={request.city}
  //         memberId={request.id}
  //         drivingSchoolId={drivingSchoolId}
  //         handleStatusUpdate={handleStatusUpdate}
  //       />
  //     ))}
  //   </div>
  // );

  // const renderAcceptedTab = () => (
  //   <div className="request-list">
  //     {acceptedMembers.map((request, index) => (
  //       <AcceptedRequestCard
  //         key={index}
  //         name={request.firstName}
  //         city={request.city}
  //       />
  //     ))}
  //   </div>
  // );

  if (pendingLoading || acceptedLoading || nonMembersLoading)
    return <div>Loading...</div>;

  return (
    <div className="admin-home-card">
      <Tabs
        id="admin-home-tabs"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        className="custom-tabs mb-3"
      >
        {/* <Tab eventKey="pendingRequests" title="Pending Requests">
          {pendingLoading ? <div>Loading...</div> : renderPendingTab()}
        </Tab>
        <Tab eventKey="acceptedRequests" title="Accepted Requests">
          {acceptedLoading ? <div>Loading...</div> : renderAcceptedTab()}
        </Tab> */}

        <Tab eventKey="pendingRequests" title="Pending Requests">
          <div className="request-list">
            {pendingMembers.map((request, index) => (
              <RequestCard
                key={index}
                name={`${request.firstName} ${request.lastName}`}
                city={request.city}
                memberId={request.id}
                drivingSchoolId={drivingSchoolId}
                handleStatusUpdate={handleStatusUpdate}
              />
            ))}
          </div>
        </Tab>

        <Tab eventKey="acceptedRequests" title="Accepted Requests">
          <div className="request-list">
            {acceptedMembers.map((request, index) => (
              <AcceptedRequestCard
                key={index}
                name={`${request.firstName} ${request.lastName}`}
                city={request.city}
                memberId={request.id}
                drivingSchoolId={drivingSchoolId}
                handleStatusUpdate={handleStatusUpdate}
              />
            ))}
          </div>
        </Tab>

        <Tab eventKey="addMembers" title="Add Members">
          <div className="add-members-section">
            <div className="search-bar">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>

            <div className="member-card-horizontal-head">
              <div className="member-avatar-head">
                <p>#</p>
              </div>
              <div className="member-info-horizontal">
                <div className="member-info-item-head">
                  <p>Name</p>
                </div>
                <div className="member-info-item-head">
                  <p>Location</p>
                </div>
                <div className="member-info-item-head">
                  <p>Phone Number</p>
                </div>
                <div className="member-info-item-head">
                  <p>Email</p>
                </div>
              </div>
              <div className="member-actions-head">
                <p>Actions</p>
              </div>
            </div>

            <div className="request-list">
              {filteredMembers.length > 0 ? (
                filteredMembers.map((member, index) => (
                  <MemberCard
                    key={index}
                    firstName={`${member.firstName} ${member.lastName}`}
                    city={member.city}
                    contactNumber={member.contactNumber}
                    email={member.email}
                  />
                ))
              ) : (
                <p>No members found.</p>
              )}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

const RequestCard = ({ name, city, memberId, drivingSchoolId, handleStatusUpdate }) => (
  <div className="member-card">
    <div
      className="member-avatar"
      style={{ backgroundColor: getAvatarColor(name) }}
    >
      <span>{name.charAt(0)}</span>
    </div>
    <div className="pending-info">
      <h5>{name}</h5>
      <p>{city}</p>
    </div>
    <div className="pending-actions">
      <button
        className="btn btn-accept"
        onClick={() => handleStatusUpdate("accepted", memberId)}
      >
        Accept
      </button>
      <button
        className="btn btn-reject"
        onClick={() => handleStatusUpdate("rejected", memberId)}
      >
        Reject
      </button>
    </div>
  </div>
);

const AcceptedRequestCard = ({
  name,
  city,
  memberId,
  drivingSchoolId,
  handleStatusUpdate,
}) => (
  <div className="member-card">
    <div
      className="member-avatar"
      style={{ backgroundColor: getAvatarColor(name) }}
    >
      <span>{name.charAt(0)}</span>
    </div>
    <div className="accept-info">
      <h5>{name}</h5>
      <p>{city}</p>
    </div>
    <div className="accept-actions">
      <button
        className="btn btn-active"
        onClick={() => handleStatusUpdate("active", memberId)}
      >
        Active
      </button>
      <button
        className="btn btn-remove"
        onClick={() => handleStatusUpdate("rejected", memberId)}
      >
        Remove
      </button>
    </div>
  </div>
);

const MemberCard = ({ firstName, city, contactNumber, email }) => {
  const validFirstName = firstName || "Unknown"; // Provide fallback if firstName is undefined
  return (
    <div className="member-card-horizontal">
      <div
        className="member-avatar"
        style={{ backgroundColor: getAvatarColor2(validFirstName) }}
      >
        <span>{validFirstName.charAt(0)}</span>
      </div>
      <div className="member-info-horizontal">
        <div className="member-info-item name-item">
          <h5>{firstName}</h5>
        </div>
        <div className="member-info-item">
          <p>{city}</p>
        </div>
        <div className="member-info-item">
          <p>{contactNumber}</p>
        </div>
        <div className="member-info-item">
          <p>{email}</p>
        </div>
      </div>
      <div className="member-actions">
        <button className="btn btn-request">Send Request</button>
      </div>
    </div>
  );
};

const getAvatarColor = (name) => {
  const colors = ["#C5A3FF", "#A3FFC5", "#FFC5A3", "#A3C5FF", "#90EE90"];
  const initial = name.charCodeAt(0);
  return colors[initial % colors.length];
};

const getAvatarColor2 = (firstName) => {
  if (!firstName || typeof firstName !== "string" || firstName.length === 0) {
    return "#FFFFFF"; // Default color if the name is not valid or empty
  }

  const colors = ["#C5A3FF", "#A3FFC5", "#FFC5A3", "#A3C5FF", "#90EE90"];
  const initial = firstName.charCodeAt(0);
  return colors[initial % colors.length];
};

export default Members;
