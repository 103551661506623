import React, { useState, useEffect } from "react";
import { Modal, Container } from "react-bootstrap";
import "./UserDetailsModal.css";
import UserModalForm from "./UserModals/UserModalForm";
import OwnerModalForm from "./OwnerModals/OwnerModalForm";
import {
  englishUser,
  sinhalaUser,
  tamilUser,
} from "../../../dummyData/UserDetailsFormData";
import { Form } from "react-bootstrap";
import { languageOptions } from "../../../dummyData/dummyData";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";

function UserDetailsModal({ showModal, handleClose }) {
  const [language, setLanguage] = useState(sinhalaUser);
  const [role, setRole] = useState("Student");
  const [isOwner, setIsOwner] = useState(false);

  const selectedLanguageLabel =
    localStorage.getItem("languageLabel") || "සිංහල";
  const selectedLanguageValue =
    localStorage.getItem("languageValue") || "sinhala";

  const { control } = useForm({
    defaultValues: {
      language: {
        value: selectedLanguageValue,
        label: selectedLanguageLabel,
      },
    },
  });

  useEffect(() => {
    if (!selectedLanguageLabel || !selectedLanguageValue) {
      localStorage.setItem("languageLabel", "සිංහල");
      localStorage.setItem("languageValue", "sinhala");
    }
  }, [selectedLanguageLabel, selectedLanguageValue]);

  const handleLanguageChanged = (selectedOption) => {
    localStorage.setItem("languageLabel", selectedOption.label);
    localStorage.setItem("languageValue", selectedOption.value);
    setLanguage(
      selectedOption.value === "sinhala"
        ? sinhalaUser
        : selectedOption.value === "tamil"
        ? tamilUser
        : englishUser
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(27 28 76)",
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgb(27 28 76)",
      color: "white",
    }),
    option: (provided, language) => ({
      ...provided,
      backgroundColor: language.isFocused
        ? "rgb(190,197,244)"
        : "rgb(27 28 76)",
      color: "white",
    }),
  };

  useEffect(() => {
    const selectedOption =
      localStorage.getItem("languageValue") || "sinhala";
    switch (selectedOption) {
      case "english":
        setLanguage(englishUser);
        break;
      case "tamil":
        setLanguage(tamilUser);
        break;
      default:
        setLanguage(sinhalaUser);
        break;
    }
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      className="container-fluid"
    >
      <Modal.Header closeButton className="border-0">
        <div className="w-100 pt-5">
          <Modal.Title>Enter Your Information to Get Started</Modal.Title>
        </div>
        <div className="me-3">
          <Form className="d-flex justify-content-center">
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="language"
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleLanguageChanged(selectedOption);
                  }}
                  classNamePrefix="select"
                  options={languageOptions}
                  placeholder="Select language"
                  styles={customStyles}
                  className="language-select"
                />
              )}
            />
          </Form>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container
          fluid
          className="d-flex align-items-center justify-content-center row g-3 py-5"
        >
          <p className="me-3 mb-0">
            Please select whether you are a Student or a Learner Owner
          </p>
          <div className="d-flex col-12 col-md-6 justify-content-center">
            <Form.Check
              className="col-lg-6 me-3"
              type="radio"
              label="Student"
              name="role"
              id="studentRadio"
              checked={role === "Student" && isOwner === false}
              onChange={() => {
                setRole("Student");
                setIsOwner(false);
              }}
            />
            <Form.Check
              className="col-lg-6"
              type="radio"
              label="Learners Owner"
              name="role"
              id="ownerRadio"
              checked={role === "Learners Owner" && isOwner === true}
              onChange={() => {
                setRole("Learners Owner");
                setIsOwner(true);
              }}
            />
          </div>
        </Container>

        {isOwner ? (
          <OwnerModalForm handleClose={handleClose} language={language} />
        ) : (
          <UserModalForm handleClose={handleClose} language={language} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UserDetailsModal;
