import React, { useState } from "react";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { AuthProvider } from "./AuthContext";
import { auth, googleProvider, facebookProvider } from "../firebase/firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import "./AuthPage.css";

function AuthPage() {
  const [isNewUser, setIsNewUser] = useState(false);
  const navigate = useNavigate();

  const toggleUserState = () => {
    setIsNewUser((prevState) => !prevState);
  };

  const handleGoogleSignIn = async () => {
    const db = getFirestore();
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;
      const userId = user.uid;
      const userEmail = user.email;
      const displayName = user.displayName;
      const accessToken = await user.getIdToken();

      // Save to local storage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("userId", userId);
      localStorage.setItem("email", userEmail);

      // Save user data in Firestore
      const docRef = doc(db, "drivingSchools", userId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          name: `${displayName}'s Learners`,
          phoneNumber: "",
          regNumber: "",
          email: userEmail,
          userId: userId,
          createdAt: new Date().toISOString(),
        });
        console.log("New user data saved.");
      } else {
        console.log("User data already exists.");
      }

      alert("Signed in and data saved successfully!");
      // alert("Signed in with Google!");
      // const accessToken = await userCredential.user.getIdToken();
      // localStorage.setItem("accessToken", accessToken);
      // if (userCredential.user.uid) {
      //   localStorage.setItem("ownerID", userCredential.user.uid);
      // }
      // if (userCredential.user.email) {
      //   localStorage.setItem("email", userCredential.user.email);
      // }
      navigate("/admin");
    } catch (error) {
      console.error("Error signing in with Google: ", error);
      alert("Google sign-in failed: " + error.message);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const userCredential = await signInWithPopup(auth, facebookProvider);
      alert("Signed in with Facebook!");
      const accessToken = await userCredential.user.getIdToken();
      localStorage.setItem("accessToken", accessToken);
      if (userCredential.user.uid) {
        localStorage.setItem("ownerID", userCredential.user.uid);
      }
      if (userCredential.user.email) {
        localStorage.setItem("email", userCredential.user.email);
      }
      navigate("/admin");
    } catch (error) {
      console.error("Error signing in with Facebook: ", error);
      alert("Facebook sign-in failed: " + error.message);
    }
  };

  return (
    <div className="auth-page-hero">
      <div className="auth-card shadow-lg">
        <AuthProvider>
          {isNewUser ? (
            <h2 className="text-center mt-4 register-txt-color">Register</h2>
          ) : (
            <h2 className="text-center mt-4 login-txt-color">Login</h2>
          )}

          <div className="text-center mt-3">
            {isNewUser ? (
              <p>
                Already have an account? Please{" "}
                <button
                  className="btn btn-link text-link-color"
                  onClick={toggleUserState}
                >
                  Login
                </button>
              </p>
            ) : (
              <p>
                No account? Please{" "}
                <button
                  className="btn btn-link text-link-color"
                  onClick={toggleUserState}
                >
                  SignUp
                </button>
              </p>
            )}
          </div>

          {isNewUser ? <SignUp /> : <SignIn />}

          {!isNewUser ? (
            <div className="text-center mb-4">
              <p>- Login with others -</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-google-fb btn-danger d-flex align-items-center justify-content-center me-2"
                  onClick={handleGoogleSignIn}
                >
                  <i className="fab fa-google me-2"></i> Google
                </button>
                <button
                  className="btn btn-google-fb btn-primary d-flex align-items-center justify-content-center"
                  onClick={handleFacebookSignIn}
                >
                  <i className="fab fa-facebook-f me-2"></i> Facebook
                </button>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </AuthProvider>
      </div>
    </div>
  );
}

export default AuthPage;
