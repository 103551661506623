import React from "react";
import { Form } from "react-bootstrap";
import { languageOptions } from "../../dummyData/dummyData";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/languageSlice";

function LanguageDropDown() {
  const dispatch = useDispatch();

    const selectedLanguage = useSelector((state) => state.language.language);

    const { control } = useForm({
      defaultValues: {
        language: selectedLanguage,
      },
    });

    const handleLanguageChanged = (selectedOption) => {
      const selectedPaperValue = localStorage.getItem("paperValue") || "first";
      localStorage.removeItem(`selectedAnswers_${selectedPaperValue}`);
      localStorage.removeItem(`timeLeft_${selectedPaperValue}`);
      localStorage.removeItem(`selectedAnswersOBO_${selectedPaperValue}`);
      localStorage.removeItem(`oBOTimeLeft_${selectedPaperValue}`);
      
      dispatch(setLanguage(selectedOption));
    };

    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(255,202,44)",
        color: "white",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "white",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "white",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: "rgb(255,202,44)",
        color: "white",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "rgb(186,197,244)"
          : "rgb(255,202,44)",
        color: "white",
      }),
    };

  return (
    <div>
      <Form className="d-flex justify-content-center">
        <Controller
          name="language"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              id="language"
              onChange={(selectedOption) => {
                field.onChange(selectedOption);
                handleLanguageChanged(selectedOption);
              }}
              classNamePrefix="select"
              options={languageOptions}
              placeholder="Select language"
              styles={customStyles}
              className="language-select"
              value={selectedLanguage}
            />
          )}
        />
      </Form>
    </div>
  );
}

export default LanguageDropDown
