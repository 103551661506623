import TopNav from "../TopNav/TopNav";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./indexAboutus.css";

import AboutUsMain from "../../assets/images/AboutUsMain.png";

const AboutUs = () => {
  return (
    <>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>
      <div className="d-flex align-items-center justify-content-center min-vh-100">
        <Container fluid className="d-flex justify-content-center">
          <Row className="d-flex justify-content-center">
            <Col
              lg={6}
              md={12}
              sm={12}
              className="d-flex flex-column align-items-center justify-content-center text-center"
            >
              <div className="content-container">
                <div className="mb-4">
                  <h3 className="fs-1">
                    About <span className="text-warning fw-bold">Us</span>
                  </h3>
                </div>
                <div className="px-3 px-md-5">
                  <p className="about-p fw-normal">
                    Welcome to MechSIT (PVT) LTD, where innovation meets
                    excellence. Founded in 2019, we are dedicated to delivering
                    exceptional products & services that cater to the diverse
                    needs of our clients. Our team of passionate professionals
                    is committed to pushing the boundaries of what’s possible,
                    ensuring every solution.
                  </p>
                </div>
                <div className="d-flex px-3 px-md-5 justify-content-center">
                  <a href="https://mecsit.com/">
                    <button
                      type="button"
                      className="btn btn-warning btn-lg mb-2 text-light"
                    >
                      See More{" "}
                      <i className="fa fa-chevron-circle-right p-2"></i>
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className="d-flex flex-column align-items-center justify-content-center mt-4 mt-lg-0"
            >
              <Image
                src={AboutUsMain}
                className="bg-img img-fluid"
                alt="About Us"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;