import React from "react";
import { useLocation } from "react-router-dom";

function EditOwnerDetails() {
  const location = useLocation();
  const { formData } = location.state || {};
  return (
    <div>
      <h1>Edit Owner Details</h1>
      <p>Name: {formData?.name || "No name provided"}</p>
    </div>
  );
}

export default EditOwnerDetails;
