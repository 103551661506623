import React, { useState } from "react";
import Swal from "sweetalert2";
import { Container } from "react-bootstrap";
import { useAddUserMutation } from "../../../../redux/service/userService";
import UserWelcomeModal from "../UserModals/UserWelcomeModal/UserWelcomeModal";

function UserModalForm({ handleClose, language }) {
  const [addNewUser] = useAddUserMutation();
  const [showUserWelcomeModal, setShowUserWelcomeModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    dob: "",
    gender: "",
    city: "",
    district: "",
    address: "",
    nic: "",
    isHaveDrivingSchool: "",
  });
  
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value, name, type } = e.target;
    if (type === "radio") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      formErrors.firstName = "First name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last name is required";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
      isValid = false;
    }
    if (!formData.contactNumber) {
      formErrors.contactNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      formErrors.contactNumber = "Mobile number must be 10 digits";
      isValid = false;
    }
    if (!formData.nic) {
      formErrors.nic = "NIC number is required";
      isValid = false;
    } else if (!/^\d{9}[VXvx]$|^\d{12}$/.test(formData.nic)) {
      formErrors.nic = "NIC must be 9 digits followed by V/X or 12 digits.";
      isValid = false;
    }
    if (!formData.dob) {
      formErrors.dob = "Date of birth is required";
      isValid = false;
    }
    if (!formData.gender) {
      formErrors.gender = "Gender is required";
      isValid = false;
    }
    if (!formData.city) {
      formErrors.city = "City is required";
      isValid = false;
    }
    if (!formData.district) {
      formErrors.district = "District is required";
      isValid = false;
    }
    if (!formData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    }
    if (!formData.isHaveDrivingSchool) {
      formErrors.isHaveDrivingSchool = "Driving school option is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    
    
    e.preventDefault();
    if (validateForm()) {  
      try {
        const result = await addNewUser(formData).unwrap();
        setShowUserWelcomeModal(true);
        // const response = await axios.post(
        //   `https://api-fyfkcojn3q-uc.a.run.app/api/user/addUser`,
        //   formData,
        // );
        console.log("Form Data:", formData);
        // Swal.fire({
        //   title: "Success",
        //   text: "Data submitted successfully!",
        //   icon: "success",
        //   confirmButtonText: "OK",
        // });
        
        
        handleClose();
        //navigate("/joinPage");
        // return response.data;
        return result;
      } catch (error) {
        console.error("Error adding document: ", error);
        Swal.fire({
          title: "Error",
          text: "Failed to submit data. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        throw error;
      }
    }
  };

     const UserHandleClose = () => {
       setShowUserWelcomeModal(false);
     };

  return (
    <div>
      <Container fluid className="d-flex justify-content-center pb-5">
        <form className="row g-3 p-4">
          <div className="col-md-6">
            <label htmlFor="firstName" className="form-label">
              {language?.firstName}
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              aria-label="First Name"
            />
            {errors.firstName && (
              <p className="text-danger">{errors.firstName}</p>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="lastName" className="form-label">
              {language?.lastName}
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            {errors.lastName && (
              <p className="text-danger">{errors.lastName}</p>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              {language?.email}
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div className="col-md-6">
            <label htmlFor="nic" className="form-label">
              {language?.nic}
            </label>
            <input
              id="nic"
              value={formData.nic}
              onChange={handleInputChange}
              className="form-control"
              type="text"
            />
            {errors.nic && <p className="text-danger">{errors.nic}</p>}
          </div>
          <div className="col-md-4">
            <label htmlFor="contactNumber" className="form-label">
              {language?.mobileNumber}
            </label>
            <input
              id="contactNumber"
              value={formData.contactNumber}
              onChange={handleInputChange}
              className="form-control"
              type="text"
            />
            {errors.contactNumber && (
              <p className="text-danger">{errors.contactNumber}</p>
            )}
          </div>
          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">
              {language?.dob}
            </label>
            <input
              id="dob"
              className="form-control"
              type="date"
              value={formData.dob}
              onChange={handleInputChange}
            />
            {errors.dob && <p className="text-danger">{errors.dob}</p>}
          </div>
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label">
              {language?.gender}
            </label>
            <select
              className="form-select"
              id="gender"
              value={formData.gender}
              onChange={handleInputChange}
            >
              <option value="">{language?.dropDownTitle}</option>
              <option value="male">{language?.genderType.male}</option>
              <option value="female">{language?.genderType.female}</option>
              <option value="other">{language?.genderType.other}</option>
            </select>
            {errors.gender && <p className="text-danger">{errors.gender}</p>}
          </div>
          <div className="col-md-6">
            <label htmlFor="city" className="form-label">
              {language?.city}
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={formData.city}
              onChange={handleInputChange}
            />
            {errors.city && <p className="text-danger">{errors.city}</p>}
          </div>
          <div className="col-md-6">
            <label htmlFor="district" className="form-label">
              {language?.district}
            </label>
            <select
              className="form-select"
              id="district"
              value={formData.district}
              onChange={handleInputChange}
            >
              <option value="">{language?.dropDownTitle}</option>
              {language?.districtList &&
                Object.keys(language.districtList).map((key) => (
                  <option key={key} value={language.districtList[key]}>
                    {language.districtList[key]}
                  </option>
                ))}
            </select>
            {errors.district && (
              <p className="text-danger">{errors.district}</p>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="address" className="form-label">
              {language?.address}
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={formData.address}
              onChange={handleInputChange}
            />
            {errors.address && <p className="text-danger">{errors.address}</p>}
          </div>
          {/* --------------------------- */}
          <div className="col-12">
            <label htmlFor="isHaveDrivingSchool" className="form-label me-2">
              {language?.radioButtonSection.question}
            </label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="isHaveDrivingSchool"
                id="inlineRadio1"
                value="yes"
                onChange={handleInputChange}
              />
              <label className="form-check-label ms-1" htmlFor="inlineRadio1">
                {language?.radioButtonSection.yes}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="isHaveDrivingSchool"
                id="inlineRadio2"
                value="no"
                onChange={handleInputChange}
              />
              <label className="form-check-label ms-1" htmlFor="inlineRadio2">
                {language?.radioButtonSection.no}
              </label>
            </div>
          </div>

          {/* ----------------------------- */}
          <div className="col-12">
            <button
              type="submit"
              className="btn submit-btn text-light px-4 py-2 mx-2"
              onClick={handleSubmit}
            >
              {language?.submit}
            </button>
          </div>
        </form>
      </Container>
      <div>
        <UserWelcomeModal
          showModal={showUserWelcomeModal}
          handleClose={UserHandleClose}
        />
      </div>
    </div>
  );
}

export default UserModalForm;
