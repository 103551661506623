import React, { useState } from "react";
import ChangePaperModal from "./PaperSelectModal";
import Col from "react-bootstrap/Col";
import PaperIMG from "../../../src/assets/PaperSelect/papersShifts.webp";
import "./PaperShiftButtons.css";
import { useNavigate } from "react-router-dom";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/HomeDummyData";

function PaperShiftButtons(props) {
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState(false);

 const handleOpenPaper = (route) => {
   setTimeout(() => {
     navigate(route); 
   });
 };

  const handleOpenChangePaperModal = (label, value) => {
    localStorage.setItem("paperLabel", label);
    localStorage.setItem("paperValue", value);
    handleOpenPaper("/paper");
  };

  

  const handleCloseChangePaperModal = () => {
    setIsModelOpen(false);
  };

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2">
      {["First", "Second", "Third", "Fourth"].map((item, index) => (
        <Col
          key={index}
          lg={3}
          md={6}
          sm={6}
          className="d-flex flex-column align-items-center justify-content-center px-3"
        >
          <button
            className="container col-12 m-4 button-design paper-button"
            onClick={() =>
              handleOpenChangePaperModal(`${item} Paper`, item.toLowerCase())
            }
          >
            <div className="jumbotron rounded">
              <img
                src={PaperIMG}
                className="img-fluid p-3"
                alt={`${item} driving license exam paper`}
              />
              <div className="justify-content-center d-flex">
                <h5 className="d-inline-flex p-2 papers-card-text">
                  {/* {props[item]} */}
                  {englishData[0][`paperNo${index + 1}`]}
                  <br />
                  {sinhalaData[0][`paperNo${index + 1}`]}
                  <br />
                  {tamilData[0][`paperNo${index + 1}`]}
                </h5>
              </div>
            </div>
          </button>
        </Col>
      ))}

      {isModelOpen && (
        <ChangePaperModal
          showModal={isModelOpen}
          handleClose={handleCloseChangePaperModal}
        />
      )}
    </div>
  );
}

export default PaperShiftButtons;
