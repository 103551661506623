import React from 'react'
import { Modal } from "react-bootstrap";
import "./OwnerWelcomeModal.css";

function OwnerWelcomeModal({ showModal, handleClose }) {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <div className="owner-modal-container">
        <div className="owner-modal-content">
          <div className="owner-welcome-section">
            <h1>Welcome</h1>
            {/* <h1 className="welcome-owner-modal-owner-text">Student!</h1> */}
          </div>
          <div className="welcome-owner-modal-get-started-section">
            <h2>Let's Get Started</h2>
            <ul>
              <li>✅ Access Learners' Driving Exam Papers</li>
              <li>✅ Find and Join Driving Schools</li>
              <li>✅ Explore Our Mobile App</li>
              <li>✅ Stay Updated with Driving Regulations</li>
              <li>✅ Exclusive Offers and Discounts</li>
            </ul>
            <p>
              Login in to your account.{" "}
              <a href="/authPage" className="welcome-owner-modal-login-link">
                Click here
              </a>
            </p>
            <button className="welcome-owner-modal-continue-btn">
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OwnerWelcomeModal
