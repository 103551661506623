import React, { useState } from "react";
import {
  FaHome,
  FaUsers,
  FaUserGraduate,
  FaUserEdit,
  FaBars,
} from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = ({ sidebarCollapsed, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  const handleNavigation = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const iconStyle = {
    fontSize: "18px",
    color: "#1c284d",
    marginRight: sidebarCollapsed ? "0" : "10px",
    transition: "color 0.3s ease",
  };

  return (
    <nav className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`}>
      <ul className="nav flex-column" aria-label="Sidebar Navigation">
        <li
          className={`nav-item ${activeTab === "/admin" ? "active" : ""}`}
          onClick={() => handleNavigation("/admin")}
          role="button"
        >
          <FaHome style={iconStyle} aria-hidden="true" />
          {!sidebarCollapsed && <span className="menu-text">Home</span>}
        </li>
        <li
          className={`nav-item ${activeTab === "/admin/members" ? "active" : ""}`}
          onClick={() => handleNavigation("/admin/members")}
          role="button"
        >
          <FaUsers style={iconStyle} aria-hidden="true" />
          {!sidebarCollapsed && <span className="menu-text">Members</span>}
        </li>
        <li
          className={`nav-item ${activeTab === "/admin/manageStudent" ? "active" : ""}`}
          onClick={() => handleNavigation("/admin/manageStudent")}
          role="button"
        >
          <FaUserGraduate style={iconStyle} aria-hidden="true" />
          {!sidebarCollapsed && (
            <span className="menu-text">Manage Students</span>
          )}
        </li>
        <li
          className={`nav-item ${activeTab === "/admin/editProfile" ? "active" : ""}`}
          onClick={() => handleNavigation("/admin/editProfile")}
          role="button"
        >
          <FaUserEdit style={iconStyle} aria-hidden="true" />
          {!sidebarCollapsed && <span className="menu-text">Edit Profile</span>}
        </li>
      </ul>
      <button
        className="toggle-btn"
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        <FaBars aria-hidden="true" />
      </button>
    </nav>
  );
};

export default Sidebar;
