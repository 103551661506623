import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./apiSlice";
import paperReducer from './paperSlice';
import languageReducer from './languageSlice';

const RootStore = configureStore({
  reducer: {
    paper: paperReducer,
    language: languageReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(RootStore.dispatch);
export default RootStore;